import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import iassign from 'immutable-assign'
import _, { cloneDeep, compact } from 'lodash'
import React, { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container, Form, Progress, Row } from 'reactstrap'

import { CallanEsgScores, DocumentAccess, DocumentSubType, DocumentType, EsgSignatoryBodyType, FileBasicInfoFragment, ManagerEsgFragment, Maybe, OrgUpdateFields, UpdateOrgInput, useDeleteOrgEsgSignatoryBodiesMutation, useGetManagerEsgLookupQuery, useManagerEsgQuery, useUpdateManagerEsgMutation, ManagerEsgScoreFragment } from '../../__generated__/graphql'
import Auth from "../../Auth/Auth"
import { DATE_API_FORMAT, FormInputField } from '../../helpers/constant'
import { convertLookupToString, excludePropertyArray, InitialStateFormat } from '../../helpers/object'
import { MANAGER_ESG_QUERY } from '../../queries/Manager'
import DocumentCompactList from '../Documents/DocumentCompactList'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { CheckBoxes, optionProps } from '../ui/CheckBoxes'
import EditButtons from '../ui/EditButtons'
import { FormInput } from '../ui/Forms/FormInput'
import PlaceHolder from './../ui/PlaceHolder'
import numbro from 'numbro'
import { appDate } from '../../Context/CalendarContext'

type Props = {
  managerId: number
  auth: Auth
}

type ManagerEsgProps = {
  __typename: "Manager"
  id: number
  options: { [name: string]: optionProps[] }
  auth: Auth
  documents: Maybe<FileBasicInfoFragment>[] | null
} & ManagerEsgFragment & ManagerEsgScoreFragment

const getInitialData = (data: React.PropsWithChildren<ManagerEsgProps>) => {
  let { managerEsgPolicy, callanEsgScores } = data
  if (!callanEsgScores) {
    callanEsgScores = {
      weightedScore: null,
      numberAnswered: null,
      completeAnswer: null
    } as CallanEsgScores
  }
  if (!managerEsgPolicy) {
    managerEsgPolicy = InitialStateFormat(ESGInput, {}) as any
  }
  if (managerEsgPolicy && !managerEsgPolicy?.managerProxyVotingEsgGuideline) {
    managerEsgPolicy.managerProxyVotingEsgGuideline = (InitialStateFormat(ProxyVotingInput, {}) as any).managerProxyVotingEsgGuideline
  }
  return {...managerEsgPolicy, callanEsgScores} as any
}

const getColor = (value: number | string) => {
  let colorClass: string = ""
  if(value < 1 || value === "N/A") {
    colorClass = "lower-than-one"
  }else if (value < 2) {
    colorClass = "lower-than-two"
  }else {
    colorClass = "lower-than-three"
  }
  return colorClass
}

const ESG_SCORE_DESCRIPTION = (
<span className="progressbar-description pl-4">
  {"The ESG score is a quantitative measure that is based on an organization's response to a set of ESG and DEI questions. The ESG score ranges from 0 to 3, with 3 being the maximum for the degree to which the organization incorporates ESG considerations into investment frameworks as well as its demonstrated commitment to DEI."}
  <br/>
  <br/>
  {"Of note, the score is meant to be a high level assessment of where a firm lies on the spectrum of ESG integration and addressing DEI and is not meant to be a precise measure nor to capture all of the elements that we would consider if doing a qualitative in-depth assessment. Scores are calculated only for organizations that respond to all questions to ensure an even playing field. Callan does not guarantee that the manager responses are accurate."}
</span>)

const sortNumberVendor =  (a: {code: any}, b: {code: any}) => {
  if(!a?.code){
    return (b?.code)? -1 : 0
  }else if(!b?.code){
    return 1
  }
  // order by code _1, _2, _3, not by value
  let order = a?.code[1] - b?.code[1]
  if(order > 0) {
    return 1
  }else if(order < 0){
    return -1
  }
  return 0
}

const Display: React.FC<ManagerEsgProps> = data => {
  const { id, options, auth, documents } = data
  const history = useHistory()
  const [currentState, setState] = useState(() => getInitialData(data))
  const [initialState, setInitial] = useState(
    currentState as { [name: string]: any }
  )

  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [updateManagerEsg] = useUpdateManagerEsgMutation()
  const [deleteOrgEsgSignatoryBodies] = useDeleteOrgEsgSignatoryBodiesMutation()

  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }
  const toggleEditMode = () => {
    setState(initialState)
    setEditMode(!editMode)
  }

  const handleSave = () => {
    setSaving(true)
    let esgData = convertLookupToString(currentState, false, ["numberVendors"])
    esgData.managerEsgPolicyDescription.signatoryBodyList = esgData.managerEsgPolicyDescription.signatoryBodyList?.map((o:any) => o.code)
    const carbonFootprintAssetClass = _.get(esgData, "managerEsgPolicyDescription.carbonFootprintAssetClass")
    const carbonFootprintScope = _.get(esgData, "managerEsgPolicyDescription.carbonFootprintScope")
    const carbonEmissionMeasurementSource = _.get(esgData, "managerEsgPolicyDescription.carbonEmissionMeasurementSource")
    let managerEsgPolicyDescription = {
      ...esgData?.managerEsgPolicyDescription,
      carbonFootprintAssetClass: carbonFootprintAssetClass?.map((el: any) => el?.code) || [],
      carbonFootprintScope: carbonFootprintScope?.map((el: any) => el?.code) || [],
      carbonEmissionMeasurementSource: carbonEmissionMeasurementSource?.map((el: any) => el?.code) || [],
    }
    const updateData = {
      patch: {
        managerEsgPolicy: excludePropertyArray({ ...esgData, managerEsgPolicyDescription}, ["__typename", "managerEsgDiversityInclusionPolicy", "callanEsgScores"]) as {
          [name: string]: any
        },
      } as OrgUpdateFields
    }
    const updateOrgInput = { id, ...updateData } as UpdateOrgInput

    // Perform list removals
    if (initialState.managerEsgPolicyDescription.signatoryBodyList) {
      const initialsignatoryBodyList = initialState.managerEsgPolicyDescription.signatoryBodyList.map((component: any) => component.code)
      const currentsignatoryBodyList = esgData.managerEsgPolicyDescription.signatoryBodyList
      const signatoryBodyListRemovals = _.difference(initialsignatoryBodyList, currentsignatoryBodyList)

      if (signatoryBodyListRemovals.length > 0) {
        deleteOrgEsgSignatoryBodies({
          variables: {
            input: {
              id: id,
              signatoryTypes: (signatoryBodyListRemovals as EsgSignatoryBodyType[])
            }
          }
        })
      }
    }

    updateManagerEsg({ variables: { input: updateOrgInput } })
      .then(result => {
        let data = result.data?.updateOrg?.org
        if (data) {
          let formattedData = getInitialData(data as ManagerEsgProps)
          setInitial(formattedData)
          setState(formattedData)
          setSaving(false)
          setEditMode(false)
        }
      })
      .catch(err => {
        console.log(err.message)
        setSaving(false)
      })
  }

  const handleSubmit = () => {
    if (editMode) {
      // preCheck
      if(!auth.checkPermissions(["edit:manager"])){
        return
      }
      //save
      handleSave()
    } else {
      // readonly
    }
  }

  const handleCheckboxesChangeToState = (
    input: optionProps[],
    subvalue: string
  ) => {
    let oldState = _.cloneDeep(currentState)
    if (subvalue === "esgSignatoryBodyTypeOptions") {
      const transformedInput = input.map(option => ({code: option.code, value: option.value}))
      const newState = iassign(
        oldState,
        currentState => currentState?.managerEsgPolicyDescription,
        process => ({
          ...process,
          signatoryBodyList: transformedInput
        })
      )
      setState(newState)
    } else if (subvalue === "diversityOversightTypeCode") {
      const transformedInput = input.map(option => {return {oversightType: {code: option.code, value: option.value}, oversightText: oldState.managerEsgDiversityInclusionPolicy?.diversityOversightTypes?.find((o:any) => o.oversightType?.code === option.code)?.oversightText || ""}})

      const newState = iassign(
        oldState,
        currentState => currentState?.managerEsgDiversityInclusionPolicy,
        process => ({
          ...process,
          diversityOversightTypes: transformedInput
        })
      )
      setState(newState)
    } else if (subvalue === "carbonFootprintAssetClassCodeOptions") {
      const transformedInput = input.map(option => ({code: option.code, value: option.value}))
      const newState = iassign(
        oldState,
        currentState => currentState?.managerEsgPolicyDescription,
        process => ({
          ...process,
          carbonFootprintAssetClass: transformedInput
        })
      )
      setState(newState)
    } else if (subvalue === "carbonFootprintScopeCodeOptions") {
      const transformedInput = input.map(option => ({code: option.code, value: option.value}))
      const newState = iassign(
        oldState,
        currentState => currentState?.managerEsgPolicyDescription,
        process => ({
          ...process,
          carbonFootprintScope: transformedInput
        })
      )
      setState(newState)
    } else if (subvalue === "carbonEmissionMeasurementSourceCodeOptions") {
      const transformedInput = input.map(option => ({code: option.code, value: option.value}))
      const newState = iassign(
        oldState,
        currentState => currentState?.managerEsgPolicyDescription,
        process => ({
          ...process,
          carbonEmissionMeasurementSource: transformedInput
        })
      )
      setState(newState)
    }
  }

  const handleInputChange = (value: any, property: string) => {
    let path = property.split(".")
    let oldState = _.cloneDeep(currentState)
    if(!_.get(oldState, path)){
      _.set(oldState, path, value)
    }
    const newState = iassign(
      oldState,
      path,
      (previousValue) => {
        if((property === "managerEsgDiversityInclusionPolicy.freqEmpSignOrReview.code" || property === "managerEsgDiversityInclusionPolicy.freqUpdatesToCode.code") && previousValue === null && false){
          return {code: value, value: ""}
        } else {
          return value
        }
      }
    )
    setState(newState)
  }

  const handleTextboxOtherChange = (value: any, table:string, array:string,  property: string) => {
    let oldState = _.cloneDeep(currentState)
    const newState = iassign(
      oldState,
      [table, array],
      (rows) => {
        let newRows = cloneDeep(rows as any[])
        let currentRow = _.find(newRows, (row) => row?.oversightType?.code === "OTH")
        if(currentRow){
          _.set(currentRow, property, value)
        }
        return newRows
      }
    )
    setState(newState)
  }

  const callanESGScoreComponent = (callanEsgScoresProps: FormInputField[]) => {
    let {property} = callanEsgScoresProps[0]
    let propertyVal: any = _.get(currentState, property)
    let {weightedScore, completeAnswer} = propertyVal
    let displayVal = (weightedScore ||0 )* 100
    let formattedValue
    let showScore = completeAnswer === 1
    if (showScore && _.isNumber(weightedScore)) {
      formattedValue = numbro(weightedScore as number).format("0.00")
    } else {
      formattedValue = "N/A"
    }
    let colorSetting = getColor(formattedValue)
    let showProgressBar = formattedValue !== "N/A"
    return (
    <div className="d-flex flex-row callanESGScore justify-content-between pb-2" key={`callanEsgScore`}>
      <div className={classnames("progressbar-container callanESGScore pr-3", colorSetting)}>
        <div className="progressbar-value-container mx-0 w-100">
          <div className="label w-100">
            <span>ESG</span>
          </div>
          <div className={"value w-100"}>
            <span>{formattedValue}</span>
          </div>
        </div>
        {showProgressBar ? (<Progress
          value={displayVal}
          max={3*100}
          style={{width:90}}
          className={"mb-3"}
        />) : (<div className="pb-1 no-progress"></div>)}
        <div className={"disclaimer w-100 pt-1 d-flex justify-content-center"}>
          {"Calculated by Callan"}
        </div>
      </div>
      {ESG_SCORE_DESCRIPTION}
    </div>)
  }

  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid>
          <Row>
            <Col>
              <div className="pane pane-toolbar sticky-top">
                {auth.checkPermissions(["edit:manager"]) &&
                  <EditButtons editMode={editMode} setEditMode={toggleEditMode} onSubmit={handleSubmit} saving={saving}/>
                }
              </div>
              <div className="pane">
                <Row>
                  <Col sm="8">
                    {callanESGScoreComponent(ManagerESGScoreInput)}
                    {ESGInput.map(
                      (
                        {
                          property,
                          label,
                          type,
                          showZero,
                          subtype,
                          placeholder,
                          optionSource,
                          readonly,
                          subClasses
                        },
                        idx
                      ) => {
                        let propertyVal: any = _.get(currentState, property)
                        let onChangeCallback = (value: any) =>{
                          if (property === "managerEsgPolicyDescription.publishReport") {
                            console.log('show uploads')
                          }

                          if(type === "select" && value === ""){
                            handleInputChange(null, property)
                          } else {
                            handleInputChange(value, property)
                          }
                        }
                        if (type && type === "checkbox") {
                          if (subtype === "multiple" && optionSource) {
                            if (optionSource === "esgSignatoryBodyTypeOptions") {
                              return (
                                <CheckBoxes
                                  optionSource={options.esgSignatoryBodyTypeOptions}
                                  options={propertyVal || []}
                                  cb={(input: optionProps[]) =>
                                    handleCheckboxesChangeToState(
                                      input,
                                      "esgSignatoryBodyTypeOptions"
                                    )
                                  }
                                  disabled={!editMode}
                                  key={"esgSignatoryBodyTypeOptions"}
                                  label={label}
                                />
                              )
                            } else if (optionSource === "carbonFootprintAssetClassCodeOptions") {
                              return (
                                <CheckBoxes
                                  optionSource={options.carbonFootprintAssetClassCodeOptions}
                                  options={propertyVal || []}
                                  cb={(input: optionProps[]) =>
                                    handleCheckboxesChangeToState(
                                      input,
                                      "carbonFootprintAssetClassCodeOptions"
                                    )
                                  }
                                  disabled={!editMode}
                                  key={"carbonFootprintAssetClassCodeOptions"}
                                  label={label}
                                />
                              )
                            } else if (optionSource === "carbonFootprintScopeCodeOptions") {
                              return (
                                <CheckBoxes
                                  optionSource={options.carbonFootprintScopeCodeOptions}
                                  options={propertyVal || []}
                                  cb={(input: optionProps[]) =>
                                    handleCheckboxesChangeToState(
                                      input,
                                      "carbonFootprintScopeCodeOptions"
                                    )
                                  }
                                  disabled={!editMode}
                                  key={"carbonFootprintScopeCodeOptions"}
                                  label={label}
                                />
                              )
                            } else if (optionSource === "carbonEmissionMeasurementSourceCodeOptions") {
                              return (
                                <CheckBoxes
                                  optionSource={options.carbonEmissionMeasurementSourceCodeOptions}
                                  options={propertyVal || []}
                                  cb={(input: optionProps[]) =>
                                    handleCheckboxesChangeToState(
                                      input,
                                      "carbonEmissionMeasurementSourceCodeOptions"
                                    )
                                  }
                                  disabled={!editMode}
                                  key={"carbonEmissionMeasurementSourceCodeOptions"}
                                  label={label}
                                />
                              )
                            } else if (optionSource === "DiversityOversightTypeCode") {
                              let otherEntry
                              if(!Array.isArray(propertyVal)){
                                propertyVal = []
                              } else {
                                otherEntry = _.find(propertyVal, (row) => row.oversightType === "OTH" || row.oversightType?.code === "OTH")
                                propertyVal = propertyVal.map((val) => val.oversightType)
                              }
                              return (
                                <React.Fragment key={`checkBoxes-${idx}`}>
                                  <CheckBoxes
                                    optionSource={_.sortBy(options.diversityOversightTypeOptions, [i => i.code === "OTH" ? 1 : 0])}
                                    options={propertyVal || []}
                                    cb={(input: optionProps[]) =>
                                      handleCheckboxesChangeToState(
                                        input,
                                        "diversityOversightTypeCode"
                                      )
                                    }
                                    disabled={!editMode}
                                    key={"diversityOversightTypeCode"}
                                    label="Oversees diversity, equity, and inclusion efforts"
                                  />
                                  {!!otherEntry &&
                                    <FormInput
                                      property={property}
                                      displayName={"If other please specify"}
                                      type={"text"}
                                      idx={idx}
                                      editMode={editMode}
                                      propertyVal={otherEntry.oversightText}
                                      updateValue={(value) => handleTextboxOtherChange(value, "managerEsgDiversityInclusionPolicy", "diversityOversightTypes", "oversightText")}
                                      subClasses={{
                                        labelClasses: "col-sm-8",
                                        inputWrapperClasses: "col-sm-4"
                                      }}
                                    />
                                  }
                                </React.Fragment>
                              )
                            }
                          }
                        }

                        if ( optionSource === "numberVendorCodeOptions") {
                          return (
                            <React.Fragment key={idx}>
                              <FormInput
                                property={property}
                                displayName={label}
                                type={type}
                                subtype={subtype}
                                idx={idx}
                                editMode={editMode}
                                propertyVal={propertyVal}
                                updateValue={onChangeCallback}
                                options={options.numberVendorCodeOptions}
                                optionsSortFunction={sortNumberVendor}
                                subClasses={{
                                  labelClasses: "col-sm-8 pl-3",
                                  inputWrapperClasses: "col-sm-4"
                                }}
                              />
                            </React.Fragment>
                          )
                        }

                        return (
                          <React.Fragment key={idx}>
                            <FormInput
                              key={idx}
                              property={property}
                              displayName={label}
                              type={type}
                              subtype={subtype}
                              placeholder={placeholder}
                              idx={idx}
                              editMode={editMode}
                              propertyVal={propertyVal}
                              updateValue={onChangeCallback}
                              optionSource={optionSource}
                              subClasses={subClasses}
                              showZero={showZero}
                              readonly={readonly}
                            />
                            { property === "managerEsgPolicyDescription.publishReport" && propertyVal == true && (
                              <DocumentCompactList
                                associationId={id}
                                associationType="Manager"
                                documents={compact(_.filter(documents, (doc) => { return doc?.type?.code === DocumentType._3 && doc?.subType?.code === DocumentSubType._14})) as FileBasicInfoFragment[]}
                                auth={auth}
                                queryDocument={MANAGER_ESG_QUERY}
                                forceValues={{description: "Sustainability/Responsible Investing Report", type: DocumentType._3, subType: DocumentSubType._14, access: DocumentAccess._1, asOfDate: appDate.format(DATE_API_FORMAT) }}
                              />
                            )}
                          </React.Fragment>
                        )
                      }
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

const ManagerESGScoreInput: FormInputField[] = [
  {
    property: "callanEsgScores",
    label: "Callan ESG Score",
    type: "float",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "callanESGScoreTooltip"
    }
  }
]

const PolicyInput: FormInputField[] = [
  {
    property: "",
    label: "Policy",
    type: "",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 mb-2",
      labelClasses: ""
    }
  },
  {
    property: "managerEsgPolicyDescription.firmWideEsgPolicy",
    label: "Firmwide ESG, SRI, sustainability, and/or responsible investment policy",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.policyDescription",
    label: "Brief description of policy",
    type: "textarea",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pb-2"
    }
  },
  {
    property: "managerEsgPolicyDescription.yearPolicyInPlace",
    label: "Year policy put in place",
    type: "number",
    subtype: "year",
    placeholder: "YYYY",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.publishReport",
    label: "Publish a quarterly or annual sustainability or responsible investing report",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.cfaDisclosure",
    label: "ESG Disclosure Statements in accordance with the CFA Institute Global ESG Disclosure Standards for investment products",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.hasDedicatedESGProfessionals",
    label: "Employ full-time dedicated ESG professionals",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  // CAL-1787
  {
    property: "managerEsgPolicyDescription.dedicatedEsgOversight",
    label: "Dedicated oversight ESG function at firm",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.numberOfDedicatedProfessionals",
    label:
      "Number of dedicated ESG professionals (strategy, technology, analyst or portfolio manager)",
    type: "number",
    showZero: true,
    placeholder: "Number of ESG professionals",
    subClasses: {
      labelClasses: "col-sm-8",
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.accessibleCentralizedRepository",
    label:
      "Centralized repository for ESG data/notes accessible to investment professionals",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  //CAL-1787
  {
    property: "managerEsgPolicyDescription.outsideVendorResearch",
    label: "Subscribe to third-party ESG research data",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.numberVendors.code",
    label: "If yes, number of third party data providers:",
    type: "select",
    subtype: "single",
    optionSource: "numberVendorCodeOptions"
  },
  {
    property: "managerEsgPolicyDescription.esgIntegratedIntoResearch",
    label: "ESG considerations integrated into research across investment platform (all asset classes)",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.unitedNationsPrinciplesSignatory",
    label: "UNPRI Signatory",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.netZeroSignatory",
    label: "Net Zero Asset Managers Initiative signatory",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.netZeroTargets",
    label: "Targets disclosed",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8 pl-3",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.signatoryToESGBodies",
    label: "Signatory to responsible investment bodies or standards other than PRI",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.signatoryBodyList",
    label: "If yes, please disclose which:",
    type: "checkbox",
    subtype: "multiple",
    optionSource: "esgSignatoryBodyTypeOptions"
  },
  //CAL-1787
  {
    property: "managerEsgPolicyDescription.reduceCarbonFootprint",
    label: "Efforts in place to measure/reduce carbon footprint at your firm",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  // CAL-2218
  {
    property: "managerEsgPolicyDescription.measureCarbonFootprint",
    label: "Ability to measure the carbon footprint of investment portfolios and report to investors",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerEsgPolicyDescription.carbonFootprintAssetClass",
    label: "If yes, please disclose which:",
    type: "checkbox",
    subtype: "multiple",
    optionSource: "carbonFootprintAssetClassCodeOptions"
  },
  {
    property: "managerEsgPolicyDescription.carbonFootprintScope",
    label: "If yes, please disclose which emissions types:",
    type: "checkbox",
    subtype: "multiple",
    optionSource: "carbonFootprintScopeCodeOptions"
  },
  {
    property: "managerEsgPolicyDescription.carbonEmissionMeasurementSource",
    label: "If yes, how are emissions measured?",
    type: "checkbox",
    subtype: "multiple",
    optionSource: "carbonEmissionMeasurementSourceCodeOptions"
  },
]

const EngagementInput: FormInputField[] = [
  {
    property: "",
    label: "Engagement",
    type: "",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 my-2",
    }
  },
  {
    property: "managerEsgPolicyDescription.belongsToAdvocacyGroups",
    label: "Belong to advocacy groups for ESG issues",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerIssuerEsgEngagementPolicy.issuerEngagementIncorporatedInEsgProcess",
    label: "Engagement with company management formally incorporated into ESG process",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerIssuerEsgEngagementPolicy.issuerEngagementInPastYear",
    label: "Had direct contact with companies about ESG goals in the past year",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerIssuerEsgEngagementPolicy.numberOfIssuerEngagementOverPastYear",
    label: "Number of companies engaged with over the past year",
    type: "number",
    showZero: true,
    placeholder: "Number of companies",
    subClasses: {
      labelClasses: "col-sm-8",
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-4"
    }
  }
]

const ProxyVotingInput: FormInputField[] = [
  {
    property: "",
    label: "Proxy Voting",
    type: "",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 my-2",
    }
  },
  {
    property:
      "managerProxyVotingEsgGuideline.sameProxyVotingGuidelineAcrossAllStrategiesAtTheFirm",
    label: "Has a global policy around proxy voting",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property: "managerProxyVotingEsgGuideline.voteOwnProxies",
    label: "Exercise ownership rights through voting own proxies",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  },
  {
    property:
      "managerProxyVotingEsgGuideline.proxyVotingPolicyAddressesESG",
    label: "Proxy voting policies specifically address ESG issues",
    type: "radio",
    subtype: "boolean",
    subClasses: {
      labelClasses: "col-sm-8",
      inputWrapperClasses: "col-sm-4"
    }
  }
]

const ESGInput: FormInputField[] = [
  ...PolicyInput,
  ...EngagementInput,
  ...ProxyVotingInput,
]

const customSortCarbonAssetClassCodes = (options: optionProps[]) => {
  // CAL-3636
  let afterINFRACodes = ["PC", "PE", "OTHER"]
  let afterINFRAOptions = _.filter(options, (option) => afterINFRACodes.includes(option.code))
  let beforeOptions = _.filter(options, (option) => !afterINFRACodes.includes(option.code))
  return [...beforeOptions, ...afterINFRAOptions] as optionProps[]
}

const ManagerOverviewESG: React.FC<Props> = ({ managerId, auth }) => {
  const { loading, error, data } = useManagerEsgQuery({
    variables: { id: managerId }
  })
  const {
    loading: lookupLoading,
    error: lookupError,
    data: lookupData
  } = useGetManagerEsgLookupQuery()
  if (loading || lookupLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-toolbar">
              {auth.checkPermissions(["edit:manager"]) &&
                <Button disabled className="ml-auto">
                  <FontAwesomeIcon icon="pen" size="xs" className="mr-2" />
                  Edit
                </Button>
              }
            </div>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else if (error || lookupError) {
    return (
      <div>
        <p>
          {(error && error.message) || (lookupError && lookupError.message)}
        </p>
      </div>
    )
  } else if (data && lookupData) {
    if (data.org && data.org.__typename === "Manager") {
      const { esgSignatoryBodyType, carbonFootprintAssetClassCode, carbonFootprintScopeCode, carbonEmissionMeasurementSourceCode, diversityOversightTypeCode, numberVendorCode } = lookupData
      const esgSignatoryBodyTypeOptions = esgSignatoryBodyType?.enumValues as optionProps[]
      const carbonFootprintAssetClassCodeOptions = customSortCarbonAssetClassCodes(carbonFootprintAssetClassCode?.enumValues as optionProps[])
      const carbonFootprintScopeCodeOptions = carbonFootprintScopeCode?.enumValues as optionProps[]
      const carbonEmissionMeasurementSourceCodeOptions = carbonEmissionMeasurementSourceCode?.enumValues as optionProps[]
      const diversityOversightTypeOptions = diversityOversightTypeCode?.enumValues as optionProps[]
      const numberVendorCodeOptions = numberVendorCode?.enumValues as optionProps[]
      const docs:FileBasicInfoFragment[] = compact(data.org.documents) || []
      return <Display options={{ esgSignatoryBodyTypeOptions, diversityOversightTypeOptions, numberVendorCodeOptions, carbonFootprintAssetClassCodeOptions, carbonFootprintScopeCodeOptions, carbonEmissionMeasurementSourceCodeOptions }} auth={auth} documents={docs} {...data.org} />
    } else {
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className="pane pane-toolbar">
                <Button disabled className="ml-auto">
                  <FontAwesomeIcon icon="pen" size="xs" className="mr-2" />
                  Edit
                </Button>
              </div>
              <div className="pane">
                <p>
                  Please check your managerId input{managerId} and try again.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }
  } else {
    return <div>org not found</div>
  }
}

export default ManagerOverviewESG
