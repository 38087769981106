import { GridApi, GridReadyEvent } from '@ag-grid-community/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { filter, find } from 'lodash'
import React, { useState } from 'react'
import { Button, ButtonDropdown, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Row } from 'reactstrap'
import Auth from '../../Auth/Auth'

import { ClientPortfolioListColumnDef } from "../../helpers/columnDefs"
import { listReportExpanded, expandReportList } from '../../helpers/report'
import { useClientReportPortfoliosQuery, ClientPortfoliosTableFragment, ReportPortfoliosFragment, PlanOverviewFragment, ReportCategoryCode } from '../../__generated__/graphql'
import SortableTable from "../Shared/SortableTable"
import PlaceHolder from '../ui/PlaceHolder'
import {CsvExportLimitModal} from '../Shared/CsvExportLimitModal'
import { match, useHistory } from 'react-router-dom'


type idProps = {
  clientId: number
  auth: Auth
  plan: PlanOverviewFragment
  match: match<{ reportId: string }>
}

const ClientPortfolioList: React.FC<idProps> = ({ clientId, match, auth, plan }) => {
  const [search, setSearch] = useState("")
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const history = useHistory()
  const [csvExportModalOpen, setCsvExportModalOpen] = useState(false)
  const [reportDropdownOpen, setReportDropdownOpen] = useState(false)
  const reportId = match.params.reportId ? parseInt(match.params.reportId) : 0
  const currentReport = find(plan.report, ["id", reportId])
  const fundReports = filter(plan?.report, { active: true, category: { code: ReportCategoryCode.FPROF } }) as ReportPortfoliosFragment[]

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const { loading, data, error } = useClientReportPortfoliosQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: clientId }
  })

  const openCsvExportModal = () => {
    setCsvExportModalOpen(!csvExportModalOpen)
  }

  const exportToCsv =() => {
    let exportedRows = 0;
    console.log("test")
    gridApi?.exportDataAsCsv({
      onlySelected: gridApi?.getSelectedRows().length > 0,

      shouldRowBeSkipped: () => {
        exportedRows++
        if(exportedRows > 1000){
          openCsvExportModal()
        }
        return exportedRows > 1000
      }
    })
  }

  const urlWithoutType = reportId
    ? match.url.slice(0, -match.params.reportId.length - 1)
    : match.url

  const heading = (
    <>
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="mr-2 pr-3 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyDown={handleEnterKeyDown}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        <div>
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
          {fundReports.length > 1 &&
            <ButtonDropdown isOpen={reportDropdownOpen} toggle={() => setReportDropdownOpen(!reportDropdownOpen)} className="ml-1 btn-thin">
              <DropdownToggle caret className="mt-0 text-callan-blue border-blue-80">
                {currentReport?.name}
              </DropdownToggle>
              <DropdownMenu>
                {fundReports.map((fundReport, idx) => {
                  return(
                    <DropdownItem key={idx} onClick={() => history.push(`${urlWithoutType}/${fundReport.id}`)} className="d-flex background-white text-text-color position-relative">
                      {fundReport.id === reportId && <div className="quartile-check"><FontAwesomeIcon icon="check" /></div>}
                      <span className="ml-2 pl-1">{`${fundReport?.name}`}</span>
                    </DropdownItem>
                  )
                })}
              </DropdownMenu>
            </ButtonDropdown>
          }
        </div>
        <CsvExportLimitModal
          modalOpen={csvExportModalOpen}
          setModalOpen={setCsvExportModalOpen}
        />
      </div>
    </>
  )

  if (loading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data  && data.org?.__typename === 'Client' && data.org.report) {
    const report = find(data.org.report, ["id", reportId]) as ReportPortfoliosFragment
    let showList:listReportExpanded[] | undefined = undefined
    if(report?.liveList){
      showList = expandReportList(report.liveList)
    } else if (!!report?.draftList && auth.checkPermissions(["view:draft_reports"])){
      showList = expandReportList(report.draftList)
    }
    if(!showList){
      return (
        <Container fluid>
          {heading}
          <Row>
            <Col>
              <div className='pane pane-table'>
                <p>Report not available. Please publish the report to proceed.</p>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }

    let tableData = showList?.reduce((result: ClientPortfoliosTableFragment[], entry)=>{
      const recursivelyIterate = (entry:listReportExpanded):ClientPortfoliosTableFragment[] => {
        if(!!entry.subGroup){
          const subMap = entry.subGroup.flatMap((subEntry) => recursivelyIterate(subEntry))
          if(!entry.portfolio || entry.portfolio.dataType?.code === "TOT"){
            return subMap
          } else {
            return [entry.portfolio, ...subMap]
          }
        } else {
          if(!entry.portfolio || entry.portfolio.dataType?.code === "TOT"){
            return []
          } else {
            return [entry.portfolio]
          }
        }
      }
      const iteratedList = recursivelyIterate(entry)
      return [...result, ...iteratedList]
    }, []) as ClientPortfoliosTableFragment[]

    const onReady = (event:GridReadyEvent) => {
      setGridApi(event.api)
      event.api!.setFilterModel({
        'active': {
          filterType: 'set',
          values: ["Active"]
        }
      })
    }
    let colDef = ClientPortfolioListColumnDef()
    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column">
        {heading}
        <SortableTable
          loading={loading}
          filterText={search}
          columnDefs={colDef}
          tableData={tableData}
          onReady={onReady}
          pageSize={100}
        />
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}



export default ClientPortfolioList
