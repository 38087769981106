import { History } from 'history'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { clone, cloneDeep, compact, filter, find, findIndex, first, flatMap, get, groupBy, intersection, isEqual, isNull, last, map, remove, set, uniq, uniqueId } from 'lodash'
import { ComponentApprovalCode, ListDetailFragment, ListDetailItemsFragment, ListMemberIdType, ListOrder, MeFragment, ReportSimpleListMemberFragment, ReportsListFragment, ReportsListFragmentDoc, ReportsListMemberFragment, useCreateListHeaderMutation, useDeleteListHeaderMutation, useSetListOrderMutation, useUpdateListHeaderMutation } from '../../../__generated__/graphql'
import Auth from '../../../Auth/Auth'
import { CombinedListExpanded, expandableReportList, expandableReportListSearch, expandReportList, getExpandableReportLocation, listReportExpanded, recursivelyOrderReport } from '../../../helpers/report'
import { ReportDisplayType } from './ReportComponent'
import { listExpanded, nicelyOrderList, recursivelyOrderItems, removeRecursiveItems, typeIdMapping, updateListItem } from '../../../helpers/list'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FormInput } from '../../ui/Forms/FormInput'
import MiddleEllipsis from '../../Shared/MiddleEllipsis'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'
import EditButtons from '../../ui/EditButtons'

interface ReportSidebarProps {
  clientId?: number
  planId?: number
  reportId?: number
  history?: History
  portfolioId?: number
  tree: (CombinedListExpanded)[]
  list?: ReportsListFragment | ListDetailFragment
  setEditedList?: React.Dispatch<React.SetStateAction<ReportsListFragment | ListDetailFragment | undefined>>
  view: ReportDisplayType
  externalLink?: (selected: (CombinedListExpanded)) => void
  startOpen?: boolean
  search?: string
  holderClass?: string
  editMode?: boolean
  listType: "report" | "list"
  reorderModal?: boolean
  noCollapse?: boolean
}

interface dragProps {
  uniqId: string
  subGroupId?: number
}

const ReportSidebar: React.FC<ReportSidebarProps> = (props) => {
  const { portfolioId, history, clientId, planId, reportId, tree, list, setEditedList, view, externalLink, startOpen, search, holderClass, editMode, listType, reorderModal, noCollapse} = props
  const sidebarRef = useRef<HTMLDivElement>(null)
  const pathToCurrent = getExpandableReportLocation(tree, portfolioId)
  const [openItems, setOpenItems] = useState<(number | string)[]>(pathToCurrent)
  const [expanded, setExpanded] = useState(!!startOpen)
  let allOpenableItems = expandableReportList(tree)
  const allExpanded = isEqual(intersection(allOpenableItems, openItems), allOpenableItems)
  const isMounted = useRef(false);
  const [shownItems, setShownItems] = useState<(number | string)[] | undefined>(undefined)
  const [highlighted, setHighlighted] = useState<string[]>([])
  const [editingHeader, setEditingHeader] = useState<string | undefined>(undefined)
  const [modalOpen, setModalOpen] = useState(false)
  const [orderedItems, openOrderedItems] = useMemo(() => {
    return [recursivelyOrderReport(tree), recursivelyOrderReport(tree, openItems)]
  }, [tree, openItems])
  let latestGroupId = 1
  let latestBaseOrder = 0
  const allListItemsCount = filter(list?.items, (item: ReportsListMemberFragment | ListDetailItemsFragment) => {
    if(item.group === 1 && item.order && item.order > latestBaseOrder){
      latestBaseOrder = item.order
    }
    if(item.item){
      if(item.item?.__typename === "ListGroup"){
        if(item.item.id > latestGroupId){
          latestGroupId = item.item.id
        }
      } else {
        return true
      }
    }
    return false
  }).length

  useEffect(() => {
    if(!!search){
      setShownItems(expandableReportListSearch(tree, search))
      setOpenItems(expandableReportListSearch(tree, search))
    } else {
      setShownItems(undefined)
      setOpenItems([])
    }
  }, [search])

  useEffect(() => {
    if(portfolioId){
      setOpenItems(compact(uniq([...openItems,...getExpandableReportLocation(tree, portfolioId)])))
      if (isMounted.current) { // Used to stop running on first render
        setExpanded(true);
      } else {
        isMounted.current = true;
      }
    }
  }, [portfolioId])

  useEffect(() => {
    setHighlighted([])
  }, [editMode])

  return (
    <div
      ref={sidebarRef}
      className={classNames("report-sidebar", 'overflow-y-auto', 'overflow-x-hidden', holderClass, {
        "report-sidebar-expanded": expanded,
        "report-sidebar-collapsed cursor-pointer": !expanded,
        "report-sidebar-report": listType === "report",
        "report-sidebar-list": listType === "list",
      })}
      onClick={() => !expanded && setExpanded(!expanded)}
    >
      {!expanded &&
        <div>
          <FontAwesomeIcon
            icon="bars"
          />
          <br />
          Open
        </div>
      }
      {expanded &&
        <div>
          <DndProvider backend={HTML5Backend}>
          {!noCollapse &&
            <div className='sticky-top background-white'>
              <div className="fake-link report-sidebar-expand-link z-index-1" onClick={() => allExpanded ? setOpenItems([]) : setOpenItems(allOpenableItems) }>
                {allExpanded ? "Collapse All" : "Expand All"}
              </div>
              <div className="report-sidebar-item cursor-pointer" onClick={() => setExpanded(!expanded)}>
                — <span className="text-blue-100">Close</span>
              </div>
            </div>
          }
          {!reorderModal && editMode && setEditedList &&
            <ListSidebarToolbar
              selectedItems={highlighted}
              setEditedList={setEditedList}
              orderedItems={orderedItems}
              listType={listType}
              tree={tree}
              setEditingHeader={setEditingHeader}
              openItems={openOrderedItems}
            />
          }
          {tree?.map((listItem: (CombinedListExpanded), idx) => {
            return(
              <SidebarItem
                key={listItem.uniqId}
                clientId={clientId}
                planId={planId}
                reportId={reportId}
                history={history}
                list={listItem}
                setEditedList={setEditedList}
                selectedId={portfolioId}
                openItems={openItems}
                shownItems={shownItems}
                currentHierarchy={[]}
                setOpenItems={setOpenItems}
                view={view}
                externalLink={externalLink}
                editMode={!reorderModal && editMode}
                highlighted={highlighted}
                setHighlighted={setHighlighted}
                orderedItems={orderedItems}
                editingHeader={editingHeader}
                setEditingHeader={setEditingHeader}
                latestGroupId={latestGroupId}
                listType={listType}
                reorderModal={reorderModal}
              />
            )
          })}
          <SidebarInsert
            setEditedList={setEditedList}
            highlighted={highlighted}
            currentHierarchy={[]}
            orderedItems={orderedItems}
            depth={0}
            group={1}
            order={latestBaseOrder+1}
          />
          </DndProvider>
        </div>
      }
    </div>
  )
}

interface SidebarItemProps {
  clientId?: number
  planId?: number
  reportId?: number
  history?: History
  list: (CombinedListExpanded)
  selectedId?: (number | string)
  openItems: (number | string)[]
  shownItems?: (number | string)[]
  currentHierarchy: string[]
  setOpenItems: (newOpenItems: (number | string)[]) => void
  view: ReportDisplayType
  externalLink?: (selected: (CombinedListExpanded)) => void
  editMode?: boolean
  highlighted: string[]
  setHighlighted: (newHighlighted: string[]) => void
  orderedItems: (CombinedListExpanded)[]
  editingHeader?: string
  latestGroupId: number
  setEditingHeader: React.Dispatch<React.SetStateAction<string | undefined>>
  setEditedList?: React.Dispatch<React.SetStateAction<ReportsListFragment | ListDetailFragment | undefined>>
  listType: "report" | "list"
  reorderModal?: boolean
}

export const SidebarItem: React.FC<SidebarItemProps> =({clientId, planId, reportId, list, currentHierarchy, selectedId, openItems, shownItems, history, setOpenItems, view, externalLink, editMode, highlighted, setHighlighted, orderedItems, editingHeader, setEditingHeader, setEditedList, latestGroupId, listType, reorderModal}) => {
  const selected = selectedId === list.id
  const [type] = list.uniqId.split(":")
  const hasSubgroup = !!list.subGroup && list.subGroup.length > 0
  const depth = currentHierarchy.length
  const isOpen = openItems.includes(list.id)
  let approvalStatus = "no-components"
  const editText = list.uniqId === editingHeader && editMode && type === "list_header"
  const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if(isOpen){
      let removedArray = clone(openItems)
      remove(removedArray, (id) => list.id === id)
      setOpenItems(removedArray)
    } else {
      setOpenItems([...openItems, list.id])
    }
  }
  const portfolio = 'portfolio' in list ? list.portfolio : undefined
  const unused = 'unused' in list ? list.unused : undefined
  const uniqId = list.uniqId
  const uniqCssSelector = (reorderModal ? "modal" : "noModal") + uniqId.replace(":", "-").replace("^", "-")
  const [{isDragging}, drag, preview] = useDrag(
    () => ({
      type: "listItem",
      item: { uniqId: list.uniqId, subGroupId: list.subGroupId },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging()
        }
      },
      canDrag: () => {
        return editMode || false
      },

    }),
    [list.uniqId, editMode],
  )

  const [{isOverTop, canDropTop}, dropTop] = useDrop(() => ({
    accept: "listItem",
    drop: (dragItem:dragProps, monitor) => {
      if(!setEditedList){
        return
      }
      setEditedList((editedList)=> {
        if(!editedList){
          return editedList
        }
        let allItems = highlighted.includes(dragItem.uniqId) ? highlighted : [dragItem.uniqId]
        const removeList = [list.uniqId, ...currentHierarchy]
        const finalList = removeRecursiveItems(allItems, orderedItems, removeList)
        // Update all remaining
        if(editedList.items && finalList.length > 0){
          let updatedItems = compact(map(editedList.items, (item: ReportsListMemberFragment | ListDetailItemsFragment) => {
            if(typeof item !== "object") return undefined
            const foundIndex = finalList.findIndex((uniqId, idx) => {
              const [type, id] = uniqId.split(":")
              return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
            })
            if(foundIndex !== -1){
              return {
                ...item,
                order: (list.order || 0) - (finalList.length - foundIndex + 1)/(finalList.length + 2),
                group: list.group,
              }
            }
            return item
          }))
          return {...editedList, items: nicelyOrderList(updatedItems)}
        }
        return editedList
      })
    },
    collect: (monitor) => {
      return {
        isOverTop: monitor.isOver(),
        canDropTop: monitor.canDrop(),
      }
    }
  }), [highlighted, orderedItems])

  const [{isOverInto, canDropInto}, dropInto] = useDrop(() => ({
    accept: "listItem",
    drop: (dragItem:dragProps, monitor) => {
      if(!setEditedList){
        return
      }
      setEditedList((editedList)=> {
        if(!editedList){
          return editedList
        }
        let allItems = highlighted.includes(dragItem.uniqId) ? highlighted : [dragItem.uniqId]
        const removeList = [list.uniqId, ...currentHierarchy]
        const finalList = removeRecursiveItems(allItems, orderedItems, removeList)
        // Update all remaining
        const newGroupId = latestGroupId + 1
        if(editedList.items && finalList.length > 0){
          let updatedItems = flatMap(editedList.items, (item: ReportsListMemberFragment | ListDetailItemsFragment) => {
            if(typeof item !== "object") return item

            // Create new group with current as first item
            const [type, id] = uniqId.split(":")
            if(item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id && !hasSubgroup){
              let editedItems = cloneDeep(editedList.items) || []
              const [type, id] = uniqId.split(":")
              let editedItem = first(remove(editedItems, (item) => {
                return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
              }))
              if(editedItem){
                // Create Group and replace item with group and assign
                const newGroup:ListDetailItemsFragment = {
                  __typename: 'ListMember',
                  order: editedItem.order,
                  group: editedItem.group,
                  type: ListMemberIdType.list_group,
                  fromList: undefined,
                  item: {
                    __typename:"ListGroup",
                    id: newGroupId,
                  },
                }
                const newItem = {
                  ...editedItem,
                  order: 0,
                  group: newGroupId
                }
                return [newGroup, newItem]
              }
              return {
                ...item,
                order: (list.order || 0),
                group: list.group,
              }
            }
            const foundIndex = finalList.findIndex((uniqId, idx) => {
              const [type, id] = uniqId.split(":")
              return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
            })

            // Add Other Items to group
            if(foundIndex !== -1){
              const modifier = (foundIndex + 1)/(finalList.length + 2)
              if(!hasSubgroup){
                let editedItems = cloneDeep(editedList.items) || []
                const [type, id] = finalList[foundIndex].split(":")
                let editedItem = first(remove(editedItems, (item) => {
                  return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
                }))
                if(editedItem){
                  // Just add item to group
                  const newItem = {
                    ...editedItem,
                    order: 0 + modifier,
                    group: newGroupId
                  }
                  return newItem
                }
                return {
                  ...item,
                  order: (list.order || 0) + modifier,
                  group: list.group,
                }
              } else {
                return {
                  ...item,
                  order: (list.subGroup && ((list.subGroup?.length || 0) > 0)) ? (last(list?.subGroup)?.order || 0) + (modifier) : modifier ,
                  group: list.subGroupId,
                }
              }
            }
            return item
          })
          return {...editedList, items: nicelyOrderList(updatedItems)}
        }
        return editedList
      })
    },
    collect: (monitor) => {
      return {
        isOverInto: monitor.isOver(),
        canDropInto: monitor.canDrop(),
      }
    }
  }), [highlighted, orderedItems])

  const isHighlighted = highlighted?.includes(list.uniqId)
  const handleNameChange = (value: string) => {
    if(!setEditedList){
      return
    }
    setEditedList((editedList) => {
      if(!editedList?.items) return

      const updatedItems = updateListItem(editedList.items, list.uniqId, (item)=>{
        if(item.type === ListMemberIdType.list_header){
          return {
            ...item,
            item: {
              __typename: "ListHeader",
              id: list.id as number,
              text: value,
            }
          }
        }
        return item
      })
      return {...editedList, items: nicelyOrderList(updatedItems) as ReportSimpleListMemberFragment[]}
    })
  }
  const removeHeader = () => {
    if(!editMode || !setEditedList) return
    if(type === "list_header"){
      setEditedList((editedList) => {
        let cloneItems = cloneDeep(editedList?.items) as ListDetailItemsFragment[]
        if(cloneItems){
          remove(cloneItems, (item) => item.type === ListMemberIdType.list_header && item.item?.__typename === "ListHeader" && item.item.id === list.id)
        }
        if(editedList){
          return {...editedList, items: nicelyOrderList(cloneItems)}
        }
      })
    } else if (listType === "list"){
      setEditedList((editedList) => {
        if(!editedList?.items) return
        let updatedItems = cloneDeep(editedList.items)
        if(list.subGroupId){
          // If a group remove the entire group and then nicelyOrderLists will remove everything in the subgroup
          updatedItems = updateListItem(updatedItems, `list_group:${list.subGroupId}`, (item)=>{
            return undefined
          })
        }
        updatedItems = updateListItem(updatedItems, list.uniqId, (item)=>{
          return {
            ...item,
            order: null,
            group: null,
          }
        })
        return {...editedList, items: nicelyOrderList(updatedItems)}
      })
    }
  }
  const handleEnter = () => {
    if(editText){
      setEditingHeader(undefined)
    }
  }
  if(get(list, `approvalCount[${ComponentApprovalCode._1}]`, 0) > 0){
    approvalStatus = "needs-review"
  } else if(get(list, `approvalCount[${ComponentApprovalCode._2}]`, 0) > 0){
    approvalStatus = "reviewed"
  } else if(get(list, `approvalCount[${ComponentApprovalCode._3}]`, 0) > 0){
    approvalStatus = "approved"
  }
  if(view !== ReportDisplayType.Draft) {
    // Only show approval on Draft view
    approvalStatus = selected ? "approved" : "duplicate-background-border"
  }
  if(shownItems && !shownItems.includes(list.id)){
    return <></>
  }
  const selectedCount = highlighted.includes(list.uniqId) ? highlighted.length : 1
  const showUntitled = ["list_header"].includes(type) && !list.name
  return (
    <>
    {isOverTop &&
      <div className='d-flex align-items-center' style={{ marginLeft: 30 + depth * 15, lineHeight: "13px" }}>
        <FontAwesomeIcon icon="caret-right" className="text-olive-100" style={{marginRight: 10}} />
        <div className='d-inline-block border-top border-olive-100 drop-insert-line'></div>
      </div>
    }
      <div
        ref={drag}
        className={classNames(
          `report-sidebar-item report-sidebar-depth-${depth} drop-target-hover`,
          {
            selected: (selected && !editMode),
            'is-selected': isHighlighted || isDragging,
            'has-subgroup': hasSubgroup,
            'has-components':
              portfolio?.hasComponents ||
              (view === ReportDisplayType.External && !unused),
            // 'drop-target-hover-top': isOverTop,
            // 'drop-target-hover-bottom': isOverInto && !hasSubgroup,
            'drop-target-into': isOverInto,
            'drag-is-dragging': isDragging,
            'is-header': ["list_header"].includes(type),
            'is-editing': editMode,
          },
          approvalStatus
        )}
        onClick={(e) => {
          if(editMode) {
            if(e.detail === 2){
              setEditingHeader(list.uniqId)
            } else {
              setEditingHeader(undefined)
            }
            // If ctrl or cmd is pressed, add to highlighted
            if (e.ctrlKey || e.metaKey){
              if (isHighlighted){
                let removedArray = clone(highlighted)
                remove(removedArray, (id) => list.uniqId === id)
                setHighlighted(removedArray)
              } else {
                setHighlighted([...highlighted, list.uniqId])
              }
            } else if (e.shiftKey) {
              // If shift is pressed, add all between
              let start = orderedItems.findIndex((item) => item.uniqId === first(highlighted))
              let end = orderedItems.findIndex((item) => item.uniqId === list.uniqId)
              if(start > end){
                [start, end] = [end, start]
              }
              let between = orderedItems.slice(start, end + 1)
              let betweenIds = map(between, (item) => item.uniqId)
              let removedArray = clone(highlighted)
              remove(removedArray, (id) => betweenIds.includes(id))
              setHighlighted([...removedArray, ...betweenIds])
            } else {
              setHighlighted([list.uniqId])
            }
          } else {
            if (view === ReportDisplayType.Draft) {
              history?.push(`/reports/${reportId}/${list.id}`)
            } else if (view === ReportDisplayType.Live) {
              history?.push(`/clients/${clientId}/${planId}/${reportId}/${list.id}`)
            } else if (view === ReportDisplayType.External && externalLink) {
              externalLink(list)
            }
          }
        }}
      >
        <div
          ref={preview}
          className={classNames('report-sidebar-drag-preview text-blue-120 p-2 white-space-nowrap')}
        >
          <FontAwesomeIcon icon="plus" className="mr-2 text-blue-120" />
          {selectedCount > 1 ? `${selectedCount} Items` : list.name}
        </div>
        {canDropTop &&
          <div ref={dropTop} className={classNames("drop-target-top",{"drop-target-extended": isOverTop})}/>
        }
        {canDropInto &&
          <div ref={dropInto} className="drop-target-bottom"/>
        }
        <div className='report-sidebar-drag'>
          <FontAwesomeIcon icon="grip-vertical" className="text-blue-120" />
        </div>
        <div
          style={{ marginLeft: depth * 15 }}
          className="d-flex align-items-center w-100"
        >
          <div
            onClick={(e) => handleExpand(e)}
            className={`report-sidebar-caret`}
          >
            {hasSubgroup && (
              <FontAwesomeIcon
                icon={isOpen ? 'caret-down' : 'caret-right'}
                size="sm"
              />
            )}
          </div>
          <div className={classNames("w-100 d-flex align-items-center", {"mw-365": listType === "report" && !reorderModal, "mw-285": listType === "list", "mw-175": listType === "report" && reorderModal})} style={{ paddingRight: depth * 15 }}>
            {/* {hasSubgroup &&
              <div className="label-blue-70 mr-2"/>
            }
            {!hasSubgroup &&
              <div className="dot-blue-70 mr-2"/>
            } */}
            <div className="report-sidebar-title">
              <div className="d-flex align-items-center">
                {editText ?
                  <FormInput
                    property={"name"}
                    displayName=""
                    placeholder={"Page Name"}
                    type={"text"}
                    idx={list.uniqId}
                    editMode={editText}
                    propertyVal={list.name}
                    updateValue={(value) => handleNameChange(value)}
                    subClasses={{wrapperClasses: editText ? "stop-select flex-grow-1" : "flex-grow-1"}}
                    autoFocus={editText}
                    handleEnter={() => handleEnter()}
                  /> :
                  <>
                    {listType === "list" &&
                      <div className="flex-grow-1 text-truncate" onClick={() => ["list_header"].includes(type) && setEditingHeader(list.uniqId)} id={"order-"+uniqCssSelector}>
                        <MiddleEllipsis text={showUntitled ? "Untitled" : list.name} />
                      </div>
                    }
                    {listType === "report" &&
                      <span id={"order-"+uniqCssSelector}>
                        {showUntitled ? "Untitled" : list.name}
                      </span>
                    }
                    <UncontrolledTooltip placement="bottom" target={"order-"+uniqCssSelector}>
                      {showUntitled ? "Untitled" : list.name}
                    </UncontrolledTooltip>
                    {listType === "list" && !["list_header"].includes(type) &&
                      <div className='text-gray-50 ml-1'>({list.id})</div>
                    }
                    {/* {["list_header"].includes(type) && editMode &&
                      <FontAwesomeIcon
                        icon="pen"
                        className="text-blue-100 ml-1"
                        onClick={() => setEditingHeader(list.uniqId)}
                      />
                    } */}
                  </>}
              </div>
            </div>
            <div className="d-inline-flex hover-swap-icon hover-display-item report-sidebar-remove" onClick={() => removeHeader()}>
              {(["list_header"].includes(type) || listType === "list") &&
                <>
                  <FontAwesomeIcon icon="times-circle" className="on-hover"/>
                  <FontAwesomeIcon icon="times" className="off-hover"/>
                </>
              }
            </div>
            {/* <div className="report-sidebar-subtitle">
              {list.subTitle}
            </div> */}
          </div>
        </div>
      </div>
      {isOpen &&
        hasSubgroup &&
        list.subGroup?.map((listItem: (CombinedListExpanded), idx) => {
          return (
            <SidebarItem
              key={listItem.uniqId}
              clientId={clientId}
              planId={planId}
              reportId={reportId}
              history={history}
              list={listItem}
              setEditedList={setEditedList}
              selectedId={selectedId}
              openItems={openItems}
              shownItems={shownItems}
              currentHierarchy={[...currentHierarchy, list.uniqId]}
              setOpenItems={setOpenItems}
              view={view}
              externalLink={externalLink}
              highlighted={highlighted}
              setHighlighted={setHighlighted}
              editMode={editMode}
              orderedItems={orderedItems}
              editingHeader={editingHeader}
              setEditingHeader={setEditingHeader}
              latestGroupId={latestGroupId}
              listType={listType}
              reorderModal={reorderModal}
            />
          )
        })}
    </>
  )
}

interface SidebarInsertProps {
  setEditedList?: React.Dispatch<React.SetStateAction<ReportsListFragment | ListDetailFragment | undefined>>
  highlighted: string[]
  currentHierarchy: string[]
  orderedItems: (CombinedListExpanded)[]
  depth: number
  uniqueId?: string
  order?: number
  group?: number
}

export const SidebarInsert: React.FC<SidebarInsertProps> = (props) => {
  const {setEditedList, highlighted, currentHierarchy, orderedItems, depth, uniqueId, order, group} = props

  const [{isOverTop, canDropTop}, dropTop] = useDrop(() => ({
    accept: "listItem",
    drop: (dragItem:dragProps, monitor) => {
      if(!setEditedList){
        return
      }
      setEditedList((editedList)=> {
        if(!editedList){
          return editedList
        }
        let allItems = highlighted.includes(dragItem.uniqId) ? highlighted : [dragItem.uniqId]
        const removeList = compact([uniqueId, ...currentHierarchy])
        const finalList = removeRecursiveItems(allItems, orderedItems, removeList)
        // Update all remaining
        if(editedList.items && finalList.length > 0){
          let updatedItems = compact(map(editedList.items, (item: ReportsListMemberFragment | ListDetailItemsFragment) => {
            if(typeof item !== "object") return undefined
            const foundIndex = finalList.findIndex((uniqId, idx) => {
              const [type, id] = uniqId.split(":")
              return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
            })
            if(foundIndex !== -1){
              return {
                ...item,
                order: (order || 0) - (finalList.length - foundIndex + 1)/(finalList.length + 2),
                group: group,
              }
            }
            return item
          }))
          return {...editedList, items: nicelyOrderList(updatedItems)}
        }
        return editedList
      })
    },
    collect: (monitor) => {
      return {
        isOverTop: monitor.isOver(),
        canDropTop: monitor.canDrop(),
      }
    }
  }), [orderedItems, highlighted, currentHierarchy, setEditedList, order, group, uniqueId])

  return (
    <div className='position-relative' style={{height: 40, paddingLeft: 25}}>
      {isOverTop &&
        <div className='d-flex align-items-center' style={{ marginLeft: 30 + depth * 15, lineHeight: "13px" }}>
          <FontAwesomeIcon icon="caret-right" className="text-olive-100" style={{marginRight: 10}} />
          <div className='d-inline-block border-top border-olive-100 drop-insert-line'></div>
        </div>
      }
      {(canDropTop || true) &&
        <div ref={dropTop} className={classNames("drop-target-top",{"drop-target-extended": isOverTop})}/>
      }
    </div>
  )
}

interface ListSidebarToolbarProps {
  tree: (CombinedListExpanded)[]
  // editMode: boolean
  // list: ListDetailFragment
  setEditedList: React.Dispatch<React.SetStateAction<ReportsListFragment | ListDetailFragment | undefined>>
  // listHeaders: ListHeaderTracker[]
  // setListHeaders: React.Dispatch<React.SetStateAction<ListHeaderTracker[]>>
  selectedItems: string[]
  orderedItems: (CombinedListExpanded)[]
  setEditingHeader: React.Dispatch<React.SetStateAction<string | undefined>>
  // setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  listType: "report" | "list"
  openItems: (CombinedListExpanded)[]
}

export const ListSidebarToolbar: React.FC<ListSidebarToolbarProps> = ({selectedItems, setEditedList, orderedItems, tree, listType, setEditingHeader, openItems}) => {

  const handleToEndButton = (direction: "top"|"bottom") => {
    if(!selectedItems?.length){
      return
    }
    setEditedList((editedList) => {
      if(!editedList?.items) return
      const finalList = removeRecursiveItems(selectedItems, orderedItems, [])
      if(editedList.items && finalList.length > 0){
        let updatedItems = map(editedList.items, (item: ReportsListMemberFragment | ListDetailItemsFragment) => {
          if(typeof item !== "object") return item
          const foundIndex = finalList.findIndex((uniqId, idx) => {
            const [type, id] = uniqId.split(":")
            return item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id
          })
          if(foundIndex !== -1){
            const modifier = (foundIndex + 1)/(finalList.length + 2)
            const baseNumber = direction === "top" ? -1 : last(tree)?.order || (tree.length + 1)
            return {
              ...item,
              order: baseNumber + modifier,
              group: 1,
            }
          }
          return item
        })
        return {...editedList, items: nicelyOrderList(updatedItems) as ReportSimpleListMemberFragment[]}
      }
    })
  }

  const handleNudgeButton = (direction: "up"|"down") => {
    if(selectedItems.length !== 1){
      return
    }
    setEditedList((editedList) => {
      if(!editedList?.items) return
      const selectedItem = selectedItems[0]
      const currentIndex = openItems.findIndex((listExpanded) => listExpanded?.uniqId === selectedItem)
      const currentItem = openItems[currentIndex]
      const sameGroup = groupBy(openItems, (item) => item?.group)[currentItem.group || ""]
      const groupIndex = sameGroup.findIndex((listExpanded) => listExpanded?.uniqId === selectedItem)
      const checkItem = direction === "up" ? openItems[currentIndex -1] : sameGroup[groupIndex+1]
      const modifier = direction === "up" ? -0.5 : 0.5
      const currentUniqId = !!currentItem?.subGroupId ? `list_group:${currentItem?.subGroupId}` : currentItem?.uniqId
      const updatedItems = updateListItem(editedList.items, currentUniqId, (item)=>{
        if(!checkItem){
          return item
        }
        return {
          ...item,
          order: (checkItem.order || 0) + modifier,
          group: checkItem.group,
        }
      })
      return {...editedList, items: nicelyOrderList(updatedItems) as ReportSimpleListMemberFragment[]}
    })
  }


  const addHeaderButton = () => {
    setEditedList((editedList) => {
      if(!editedList?.items) return
      let baseOrder = -0.5
      let baseGroup = 1
      if(selectedItems.length >= 1){
        const selectedItem = selectedItems[0]
        const currentIndex = orderedItems.findIndex((listExpanded) => listExpanded?.uniqId === selectedItem)
        const currentItem = orderedItems[currentIndex]
        baseOrder = (currentItem?.order || 0) + 0.5
        baseGroup = currentItem?.group || 1
      }
      const newHeaderId = parseInt(uniqueId()) * -1
      const defaultValue = ""
      let newHeader:ListDetailItemsFragment = {
        __typename: "ListMember",
        order: baseOrder,
        group: baseGroup,
        type: ListMemberIdType.list_header,
        item: {
          __typename: "ListHeader",
          id: newHeaderId,
          text: defaultValue
        }
      }
      let newHeaderIdString = `list_header:${newHeaderId}`
      setEditingHeader(newHeaderIdString)
      // setListHeaders([...listHeaders, {id: newHeaderId, status: "created", value: defaultValue}])
      if(!editedList?.items) return
      return {...editedList, items: nicelyOrderList([...editedList.items, newHeader]) as ReportSimpleListMemberFragment[]}
    })
  }

  const moveUp = selectedItems.length >= 1
  const levelUp = selectedItems.length >= 1
  const levelDown = selectedItems.length >= 1
  const moveDown = selectedItems.length >= 1
  const addPage = true

  return (
    <div className="list-table-edit-bar d-flex align-items-center pl-2 border border-gray30 py-2 sticky-top background-white" onClick={(e) => e.stopPropagation()}>
      <div className="border-right">
        <div className={classNames('d-inline-block toolbar-button mr-1', {"cursor-pointer": moveUp, "disabled": !moveUp})} title="Bring to Top" onClick={() => handleToEndButton("top")}>
          <FontAwesomeIcon
            icon={["fas", "arrow-to-top"]}
            size="lg"
          />
        </div>
        <div className={classNames('d-inline-block toolbar-button mr-1', {"cursor-pointer": levelUp, "disabled": !levelUp})} title="Bring up" onClick={() => handleNudgeButton("up")}>
          <FontAwesomeIcon
            icon={["fas", "level-up"]}
            size="lg"
          />
        </div>
        <div className={classNames('d-inline-block toolbar-button mr-1', {"cursor-pointer": levelDown, "disabled": !levelDown})} title="Send down" onClick={() => handleNudgeButton("down")}>
          <FontAwesomeIcon
            icon={["fas", "level-down"]}
            size="lg"
          />
        </div>
        <div className={classNames('d-inline-block toolbar-button mr-1', {"cursor-pointer": moveDown, "disabled": !moveDown})} title="Send to bottom" onClick={() => handleToEndButton("bottom")}>
          <FontAwesomeIcon
            icon={["fas", "arrow-to-bottom"]}
            size="lg"
          />
        </div>
      </div>
      <div className={classNames('d-inline-block toolbar-button ml-1', {"cursor-pointer": addPage, "disabled": !addPage})} title="Add" onClick={() => addHeaderButton()}>
        <FontAwesomeIcon
          icon={["fas", "plus"]}
          size="lg"
        />
      </div>
    </div>
  )
}

type SidebarOrderModalProps = {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
} & ReportSidebarProps

enum listSavingStatus{
  Initial = 1,
  StartUpdatingHeadings = 2,
  CreatingUpdatingHeadings = 3,
  StartOrder = 4,
  UpdatingOrder = 5,
  Error = 6,
}

export const SidebarOrderModal: React.FC<SidebarOrderModalProps> = (props) => {
  const { modalOpen, setModalOpen, list } = props

  const [editedList, setEditedList] = useState<ReportsListFragment | ListDetailFragment | undefined>(list)
  const [saving, setSaving] = useState(listSavingStatus.Initial)
  const [savingComponents, setSavingComponents] = useState(0)
  const [updatedListOrder, setUpdatedListOrder] = useState<ListOrder[]>([])

  const [createListHeader] = useCreateListHeaderMutation()
  const [updateListHeader] = useUpdateListHeaderMutation()
  const [deleteListHeader] = useDeleteListHeaderMutation()
  const [setListOrder] = useSetListOrderMutation()

  const tree = useMemo(() => {
    return editedList ? expandReportList(editedList) : []
  }, [editedList])

  useEffect(() => {
    setEditedList(list)
  }, [modalOpen])

  const closeModal = (refresh: boolean = false) => {
    setEditedList(list)
    setModalOpen(false)
  }

  const onSubmit = () => {
    setSaving(listSavingStatus.StartUpdatingHeadings)
  }

  const afterSave = () => {
    setSavingComponents(prevCount => prevCount - 1)
  }

  useEffect(() => {
    if(savingComponents < 1 && saving === listSavingStatus.CreatingUpdatingHeadings){
      setSaving(listSavingStatus.StartOrder)
    }
  }, [saving, savingComponents])

  if(list){
    if(saving === listSavingStatus.StartUpdatingHeadings){
      setSaving(listSavingStatus.CreatingUpdatingHeadings)
      // Save Order of list to be used later and updated
      const updatedOrder = compact(editedList?.items?.map((item):ListOrder | undefined => {
        if(item.group && (item.order || item.order === 0) && item.type){
          return {
            memberId: get(item, `item.${typeIdMapping[item.type]}`, "").toString(),
            idType: item.type,
            order: item.order,
            group: item.group,
          }
        }
        return undefined
      }))
      setUpdatedListOrder(updatedOrder)

      const editedHeadings = compact(editedList?.items?.map((item) => { return item.type === ListMemberIdType.list_header && item.item?.__typename === "ListHeader" && item.item }))
      const originalHeadings = compact(list?.items?.map((item) => { return item.type === ListMemberIdType.list_header && item.item?.__typename === "ListHeader" && item.item }))
      let updatedHeadings = editedHeadings.filter(x => {
        const matchingHeading = find(originalHeadings, {id: x.__typename === "ListHeader" ? x.id : undefined})
        if(matchingHeading){
          return JSON.stringify(matchingHeading) !== JSON.stringify(x)
        }
        return false
      })
      let newHeadings = editedHeadings.filter(x => {
        const matchingHeading = find(originalHeadings, {id: x.__typename === "ListHeader" ? x.id : undefined})
        if(matchingHeading){
          return false
        }
        return true
      })
      let deletedHeadings = originalHeadings.filter(x => {
        const matchingHeading = find(editedHeadings, {id: x.__typename === "ListHeader" ? x.id : undefined})
        if(matchingHeading){
          return false
        }
        return true
      })

      // Update Headings
      newHeadings.forEach((header) => {
        createListHeader({variables: {
          input: {
            listId: list?.id,
            text: header.text
          }},
          update: (cache, { data }) => {
            const matchingItem = find(editedList?.items, (item) => typeof item === "object" && "item" in item && item.item?.__typename === "ListHeader" && item.item.id === header.id) as ReportsListFragment["items"]
            const defaultPage = {
              __typename: "ListMember" as "ListMember",
              id: 0,
              name: "",
              draftLayout: null,
              liveLayout: null,
            }
            const listFragment = cache.readFragment({
              id: `List:${list?.id}`, // The value of the to-do item's cache ID
              variables: { liveView: true, draftView: true },
              fragment: ReportsListFragmentDoc,
              fragmentName: "ReportsListFragment",
            }) as ReportsListFragment | null
            if(listFragment){
              let clonedList = cloneDeep(listFragment)
              clonedList.items?.push({...defaultPage, ...matchingItem, item: data?.createListHeader})
              cache.writeFragment({
                id: `List:${list?.id}`,
                variables: { liveView: true, draftView: true },
                fragment: ReportsListFragmentDoc,
                data: clonedList,
                fragmentName: "ReportsListFragment",
              });
            }
          }}).then(result => {
          if (result && result.data) {
            setUpdatedListOrder((listOrder) => {
              let updatedOrder = cloneDeep(listOrder)
              const updatedIndex = findIndex(updatedOrder, (item) => item.idType === `list_header` && item.memberId === header.id.toString())
              if(updatedIndex !== -1){
                updatedOrder[updatedIndex].memberId = `${result.data?.createListHeader?.id}`
              }
              return updatedOrder
            })
          }
          afterSave()
        })
        .catch(err => {
          setSaving(listSavingStatus.Error)
          afterSave()
          console.error("Error Create list header:", err.message)
        })
      })
      updatedHeadings.forEach((header) => {
        updateListHeader({variables: {
          input: {
            id: header.id,
            patch: {
              text: header.text
            }
          }
        }}).then(result => {
          afterSave()
        })
        .catch(err => {
          setSaving(listSavingStatus.Error)
          afterSave()
          console.error("Error Update list header:", err.message)
        })
      })
      deletedHeadings.forEach((header) => {
        if(header.id < 0) return
        deleteListHeader({variables: {
          input: {
            id: header.id,
          }},
          update: (cache, { data }) => {
            const listFragment = cache.readFragment({
              id: `List:${list?.id}`, // The value of the to-do item's cache ID
              variables: { liveView: true, draftView: true },
              fragment: ReportsListFragmentDoc,
              fragmentName: "ReportsListFragment",
            }) as ReportsListFragment | null
            if(listFragment){
              let clonedList = cloneDeep(listFragment)
              clonedList.items = clonedList.items?.filter((item) => !(item.item?.__typename === "ListHeader" && item.item.id === header.id))
              cache.writeFragment({
                id: `List:${list?.id}`,
                variables: { liveView: true, draftView: true },
                fragment: ReportsListFragmentDoc,
                data: clonedList,
                fragmentName: "ReportsListFragment",
              });
            }
          }}).then(result => {
          afterSave()
        })
        .catch(err => {
          setSaving(listSavingStatus.Error)
          afterSave()
          console.error("Error Delete list header:", err.message)
        })
      })
      setSavingComponents(updatedHeadings.length + newHeadings.length + deletedHeadings.length)
    } else if (saving === listSavingStatus.StartOrder){
      setSaving(listSavingStatus.UpdatingOrder)
      setListOrder({
        variables:{
          input: {
            id: list?.id,
            order: updatedListOrder
          }
        }
      }).then(result => {
        setUpdatedListOrder([])
        setSaving(listSavingStatus.Initial)
        setModalOpen(false)
      })
      .catch(err => {
        setSaving(listSavingStatus.Error)
        console.error("Error Update list order:", err.message)
      })
    }
  }
  return (
    <Modal
      size='md'
      className='mt-5 order-modal'
      isOpen={modalOpen}
      toggle={() => {
        if([listSavingStatus.Initial, listSavingStatus.Error].includes(saving)){
          setModalOpen(!modalOpen)
        }
      }}
      zIndex={1499}
    >
      <ModalHeader className='fee-modal-header full-width-header'>
        <div className='d-flex justify-content-between'>
          <div>Update Pages</div>
          <div onClick={() => closeModal()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody className='pt-0'>
        <>
          <ReportSidebar
            {...props}
            reorderModal={false}
            noCollapse={true}
            list={editedList}
            setEditedList={setEditedList}
            tree={tree}
          />
        </>
      </ModalBody>
      <ModalFooter>
        <EditButtons
          className={"disable-on-white"}
          key={`edit`}
          editMode={true}
          setEditMode={() => true}
          cancelEdit={() => closeModal()}
          saving={saving !== listSavingStatus.Initial}
          // onSubmit here should use the filter to update the table.
          onSubmit={onSubmit}
        />
      </ModalFooter>
    </Modal>
  )
}

export default ReportSidebar