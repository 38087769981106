import React, { Component, useState } from "react"
import { History } from 'history'
import { Row, Col, ListGroupItem, ListGroup, Container, DropdownToggle } from "reactstrap"
import { WithTopNav } from "./Components/ui/LayoutWrapper"
import { appProps } from "./queries/extended/appProps"
import { getRecentManagers, getRecentProducts, getRecentClients } from "./helpers/session"
import { ConsultantDashboardComponentFragment,  ConsultantDashboardLayOutFragment,  LandingPage,  MeFragment,  useClientDetailsQuery, useConsultantDashboardQuery, useMeQuery, useSingleDashboardQuery } from "./__generated__/graphql"
import PlaceHolder from "./Components/ui/PlaceHolder"
import ConsultantDashboardMain from "./Components/Consultant/Dashboard/ConsultantDashboardSection"
import classnames from "classnames"
import { isCallanUser } from "./helpers/person"
import Auth from "./Auth/Auth"
import { RecentDocuments } from "./Components/Consultant/Dashboard/RecentlyViewed"
import DashboardPicker from "./Components/ui/Pickers/DashboardPicker"
import { compact } from "lodash"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"


const QueryApp: React.FC<appProps> = (props: appProps) => {
  const { loading, data, error } = useConsultantDashboardQuery()
  //const { loading: additionalDashboardLoading, data: additionalDashboardData, error: additionalDashboardError } = useSingleDashboardQuery({ variables: { id: 73 }})
  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (data?.me?.appMetadata?.firms) {
    const firms = data?.me?.appMetadata?.firms || []
    const landingPage = data?.me?.appMetadata?.landingPage
    if(landingPage === LandingPage.client_portal && firms.length > 0) {
      props.history.replace(`/clients/${firms[0]}`)
    } else if (landingPage === LandingPage.manager_products && firms.length > 0) {
      props.history.replace(`/managers/${firms[0]}/products`)
    } else if(data?.me?.dashboards && data?.me?.defaultDashboard && data?.me?.dashboards.length > 0){
      let dashboards = data?.me?.dashboards as ConsultantDashboardLayOutFragment[]
     // let dashboards = compact([...data?.me?.dashboards as ConsultantDashboardLayOutFragment[], additionalDashboardData?.dashboard])
      return <CustomDashboard defaultDashboard={data?.me?.defaultDashboard} dashboards={dashboards} auth={props.auth}/>
    } else if(isCallanUser(data.me as MeFragment)){
      return(
        <>
          <Container fluid>
            <h2>Dashboard</h2>
            <hr style={{ width: "100%" }} />
            <Row>
              <RecentClients history={props.history} width={4} />
              <RecentManagers history={props.history} width={4} />
              <RecentProducts history={props.history} width={4} />
            </Row>
          </Container>
        </>
      )
    } else {
      const firms = data?.me?.appMetadata?.firms
      return <ClientApp {...props} clientId={firms[0] || undefined}/>
    }
  }
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="pane">
            <p>
              New to the Callan questionnaire? Please go{" "}
              <a href="http://callandna.helpdocsonline.com/contact-us">here</a>{" "}
              for instructions on how to get set up.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

type CustomDashboardProps = {
  dashboards: ConsultantDashboardLayOutFragment[]
  defaultDashboard: ConsultantDashboardLayOutFragment
  auth: Auth
}

const CustomDashboard: React.FC<CustomDashboardProps>  = (props) =>{
  // /**
  //  * Handle breadcrumb render
  //  * @param {[{}]} crumbs
  //  * @return {[{}]}
  //  */
  // handleCrumbs = (crumbs: CrumbsProps) => {
  //   // Remove first crumb
  //   return crumbs.filter((c, i: number) => i !== 0)
  // }
  const dashboards = props.dashboards
  const [dashboard, setDashboard] = useState(props.defaultDashboard)

  let sections = dashboard? dashboard?.layout?.sections : []
  let result = sections?.map((section, idx) => {
    if(section?.type && section?.components){
      let draftView: boolean
      if(props.auth.checkPermissions(["view:all_managers"])){
        draftView = true
      } else {
        draftView = false
      }
      return(
        <ConsultantDashboardMain
          key={dashboard.id +"-"+ idx}
          type={section?.type}
          components={section?.components as ConsultantDashboardComponentFragment[]}
          draftView={draftView}
          auth={props.auth}
        />
        )
    }
    return <React.Fragment key={idx} />
  })

  return (
    <Container fluid>
      {dashboard && (
        <Col xs="10" sm="8" md="6">
        <div className="mb-4">
          <DashboardPicker
            key={dashboard.id}
            selectedDashboard={dashboard}
            title={`View another dashboard`}
            dashboards={dashboards}
            onClick={(dashboard:ConsultantDashboardLayOutFragment) => {
              setDashboard(dashboard)
            }}
          >

          <div className='dropdown'>
          <DropdownToggle caret>
          <h2 className="headline">
          {dashboard?.name || ""}
                        </h2>
          </DropdownToggle>
            </div>
          </DashboardPicker>
        </div>
        </Col>
      )}
      <Row>
        <DndProvider backend={HTML5Backend}>
          {result}
        </DndProvider>
      </Row>
    </Container>
    )
}

interface ClientAppProps {
  history: History
  clientId?: number
}

const ClientApp: React.FC<ClientAppProps> = ({history, clientId}) => {
  const { loading, data, error } = useClientDetailsQuery({
    // fetchPolicy: "cache-and-network",
    variables: { id: clientId || 0 },
    skip: !clientId
  })
  const overview = data?.overview?.__typename === "Client" ? data.overview : undefined
  return (
    <>
      <Container fluid>
        <h2>Welcome to Callan DNA</h2>
        <hr style={{ width: "100%" }} />
        <Row>
          {overview &&
            <Col md={3}>
              <div className="pane dashboard-pane">
                <h3 className="headline underline green-underline">
                  {overview?.name} Fund Profiles
                </h3>
                {loading &&
                  <PlaceHolder height={400} />
                }
                {!loading &&
                  <ListGroup className="recent">
                    {overview?.plans?.flatMap((plan) => plan?.report?.map((report) => {
                      if(!report?.liveList){
                        return (<React.Fragment key={report?.id}></React.Fragment>)
                      }
                      return (
                        <ListGroupItem
                          tag="a"
                          key={report?.id}
                          onClick={() => history.push(`/clients/${clientId}/${plan?.id}/${report?.id}`)}
                        >
                          <h3>
                            {report?.name}
                            <span className="chevron">&rsaquo;</span>
                          </h3>
                        </ListGroupItem>
                      )
                    }))}
                  </ListGroup>
                }
              </div>
            </Col>
          }
          <RecentManagers history={history} width={overview ? 3 : 4} />
          <RecentProducts history={history} width={overview ? 3 : 4} />
          <RecentDocuments history={history} width={overview ? 3 : 4} />
        </Row>
      </Container>
    </>
  )
}

interface RecentProps {
  history: History
  width: number
  stackable?: boolean
  limit?: number
}

const DEFAULT_STACKABLE_ITEM_LIMIT = 10

const RecentClients: React.FC<RecentProps> = ({history, width}) => {
  const recentClients = getRecentClients()
  return(
    <Col md={width}>
      <div className="pane dashboard-pane">
        <h3 className="headline underline green-underline">
          Recent Clients
        </h3>
        {recentClients.length === 0 && (
          <>
            <span className="muted-text">
              Recently viewed Clients will appear here.
            </span>

            <ListGroup className="recent">
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "85%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "55%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
            </ListGroup>
          </>
        )}

        {recentClients.length > 0 && (
          <ListGroup className="recent">
            {recentClients.map((client) => {
              return (
                <ListGroupItem
                  tag="a"
                  key={client.id}
                  onClick={() => history.push(`/clients/${client.id}`)}
                >
                  <h3>
                    {client.name}
                    <span className="chevron">&rsaquo;</span>
                  </h3>
                </ListGroupItem>
              )
            })}
          </ListGroup>
        )}
      </div>
    </Col>
  )
}

export const RecentManagers: React.FC<RecentProps> = ({history, width, stackable, limit}) => {
  const recentManagers = getRecentManagers(limit || DEFAULT_STACKABLE_ITEM_LIMIT)
  const title = stackable? "MANAGERS": "Recent Managers"
  return(
    <Col md={stackable? 12: width}>
      <div className={classnames("pane dashboard-pane", {stackable})}>
        <h3 className="headline underline green-underline">
          {title}
        </h3>
        {recentManagers.length === 0 && (
          <>
            <span className="muted-text">
              Recently viewed Managers will appear here.
            </span>

            <ListGroup className="recent">
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "85%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "55%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
            </ListGroup>
          </>
        )}

        {recentManagers.length > 0 && (
          <ListGroup className="recent">
            {recentManagers.map((manager) => {
              return (
                <ListGroupItem
                  tag="a"
                  key={manager.id}
                  onClick={() => history.push(`/managers/${manager.id}`)}
                >
                  <h3>
                    {manager.name}
                    <span className="chevron">&rsaquo;</span>
                  </h3>
                </ListGroupItem>
              )
            })}
          </ListGroup>
        )}
      </div>
    </Col>
  )
}

export const RecentProducts: React.FC<RecentProps> = ({history, width, stackable, limit}) => {
  const recentProducts = getRecentProducts(limit || DEFAULT_STACKABLE_ITEM_LIMIT)
  const title = stackable? "PRODUCTS": "Recent Products"
  return(
    <Col md={stackable? 12: width}>
      <div className={classnames("pane dashboard-pane", {stackable})}>
        <h3 className="headline underline green-underline">
          {title}
        </h3>
        {recentProducts.length === 0 && (
          <>
            <span className="muted-text">
              Recently viewed Products will appear here.
            </span>

            <ListGroup className="recent">
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "85%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "60%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "35%" }}
                ></div>
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "60%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "85%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "65%" }}
                ></div>
              </ListGroupItem>
            </ListGroup>
          </>
        )}

        {recentProducts.length > 0 && (
          <ListGroup className="recent">
            {recentProducts.map((product) => {
              return (
                <ListGroupItem
                  tag="a"
                  key={product.id}
                  onClick={() => history.push(`/products/${product.id}`)}
                >
                  <h4>{product.managerName}</h4>
                  <h3>
                    {product.name}
                    <span className="chevron">&rsaquo;</span>
                  </h3>
                </ListGroupItem>
              )
            })}
          </ListGroup>
        )}
      </div>
    </Col>
  )
}

class UnauthorizedApp extends Component<appProps, any> {
  render() {
    return (
      <>
        <Container fluid>
          <h2>Unauthorized</h2>
          <hr style={{ width: "100%" }} />
          <div className="pane dashboard-pane">
            User is not authorized. Please contact Callan at
            &nbsp;
            <a href="mailto:webinfo@callan.com">webinfo@callan.com</a>
            &nbsp;
            for permission.
          </div>
        </Container>
      </>
    )
  }
}

export default WithTopNav(QueryApp)
