import { disableFragmentWarnings, gql } from "@apollo/client"
import { FOOTNOTE_DETAIL_FRAGMENT } from "./FootnoteFragments"
import { CURRENT_ASSET_CLASS_VALUE_FRAGMENT } from "./Fragments"
import { TARGET_DEFINITION_FRAGMENT, TARGET_DETAIL_FRAGMENT } from "./Target"
disableFragmentWarnings()

export const CLIENT_PORTFOLIO_PLAN_FRAGMENT = gql`
  fragment ClientPortfolioPlanFragment on Plan {
    id
    name
    clientPortfolios {
      id
      name
    }
    footnotes {
      ...FootnoteDetailFragment
    }
  }
  ${FOOTNOTE_DETAIL_FRAGMENT}
`

export const CLIENT_PORTFOLIO_FEE_SCHEDULE_FRAGMENT = gql`
  fragment ClientPortfolioFeeScheduleFragment on ClientPortfolioFeeSchedule {
    date
    accountSize
    managementFee
  }
`

export const CLIENT_PORTFOLIO_DATED_FEE_FRAGMENT = gql`
  fragment ClientPortfolioDatedFeeFragment on ClientPortfolioDatedFee {
    date
    annualizedSubAdvisedPctOfApplicableSubadvisedAssets
    annualizedSubAdvisedPctOfTotalPortfolio
    annualizedWrapPctOfApplicableWrappedAssets
    annualizedWrapPctOfTotalPortfolio
    totalExpense
  }
`

export const CLIENT_PORTFOLIO_BASIC_FRAGMENT = gql`
  fragment ClientPortfolioBasicFragment on ClientPortfolio {
    id
    name
    relatedVehicle {
      vehicle {
        id: fundid
        name
        category {
          code
          value
        }
      }
    }
    isComposite
    dataType {
      code
      value
    }
    isActive
    isTest
    performanceTargetMap {
      target {
        targetId: id
        name
      }
      order
    }
    lists {
      group
      order
      list {
        id
        subCategory {
          code
        }
      }
    }
    bankAccount
  }
  ${CLIENT_PORTFOLIO_PLAN_FRAGMENT}
`

export const CLIENT_PORTFOLIO_SIMPLE_FRAGMENT = gql`
  fragment ClientPortfolioSimpleFragment on ClientPortfolio {
    id
    name
    isComposite
    dataType {
      code
      value
    }
    isActive
    isTest
  }
`

export const CLIENT_PORTFOLIO_TARGET_FRAGMENT = gql`
  fragment ClientPortfolioTargetFragment on ClientPortfolioTarget {
    target {
      ...TargetDetailFragment
      definition {
        ...TargetDefinitionFragment
      }
    }
    order
  }
  ${TARGET_DETAIL_FRAGMENT}
  ${TARGET_DEFINITION_FRAGMENT}
`

export const CLIENT_PORTFOLIO_FOOTNOTES_FRAGMENT = gql`
  fragment ClientPortfolioFootnotesFragment on ClientPortfolio {
    footnote {
      ...FootnoteDetailFragment
    }
    targetFootnotes {
      ...FootnoteDetailFragment
    }
  }
  ${FOOTNOTE_DETAIL_FRAGMENT}
`

export const CLIENT_PORTFOLIO_BENCHMARK_FRAGMENT = gql`
  fragment ClientPortfolioBenchmarkFragment on ClientPortfolio {
    performanceTargetMap {
      ...ClientPortfolioTargetFragment
    }
    ...ClientPortfolioFootnotesFragment
  }
  ${CLIENT_PORTFOLIO_TARGET_FRAGMENT}
  ${CLIENT_PORTFOLIO_FOOTNOTES_FRAGMENT}
`

export const CUSTOM_MEMBER_FRAGMENT = gql`
  fragment CustomMemberFragment on CustomMember {
    ... on ClientPortfolio {
      id
      name
    }
    ... on Group {
      groupId: id
      name: shortName
    }
    ... on Index {
      indexId: id
      name
    }
    ... on Target {
      ...TargetDetailFragment
    }
    ... on Vehicle {
      vehicle {
        id: fundid
        name
      }
    }
  }
`

export const CUSTOM_CHARACTERISTIC_MEMBER_FRAGMENT = gql`
  fragment CustomCharacteristicMemberFragment on CustomMember {
    ... on Vehicle {
      vehicle {
        id: fundid
        name
      }
    }
  }
`

export const CUSTOM_CHARACTERISTICS_SOURCE_FRAGMENT = gql`
  fragment CustomCharacteristicsSourceFragment on CustomCharacteristicsSource {
    startDate
    endDate
    memberType {
      code
    }
    member {
      ...CustomCharacteristicMemberFragment
    }
  }
  ${CUSTOM_CHARACTERISTIC_MEMBER_FRAGMENT}
`

export const CLIENT_PORTFOLIO_CHARACTERISTICS_FRAGMENT = gql`
  fragment ClientPortfolioCharacteristicsFragment on ClientPortfolio {
    characteristicSource {
      code
      value
    }
    customCharacteristicsSource {
      ...CustomCharacteristicsSourceFragment
    }
  }
  ${CUSTOM_CHARACTERISTICS_SOURCE_FRAGMENT}
`

export const CLIENT_PORTFOLIO_FEES_FRAGMENT = gql`
  fragment ClientPortfolioFeesFragment on ClientPortfolio {
    id
  }
`

export const CLIENT_PORTFOLIO_COMPOSITES_FRAGMENT = gql`
  fragment ClientPortfolioCompositesFragment on ClientPortfolio {
    memberOfComposites {
      startDate
      endDate
      composite {
        id
        name
      }
    }
  }
`

export const CLIENT_PORTFOLIO_COMPOSITE_MEMBERS_FRAGMENT = gql`
  fragment ClientPortfolioCompositeMembersFragment on ClientPortfolio {
    id
    name
    compositeMembers {
      startDate
      endDate
      member {
        id
        name
        isTest
      }
    }
  }
`

export const CLIENT_PORTFOLIO_CUSTOM_PERFORMANCE_MEMBER_FRAGMENT = gql`
  fragment ClientPortfolioCustomPerformanceMemberFragment on CustomPerformanceMember {
    startDate
    endDate
    memberType {
      code
      value
    }
    member {
      ...CustomMemberFragment
    }
  }
  ${CUSTOM_MEMBER_FRAGMENT}
`

export const CLIENT_PORTFOLIO_CUSTOM_PERFORMANCE_HOLDER_FRAGMENT = gql`
  fragment ClientPortfolioCustomPerformanceHolderFragment on CustomPerformance {
    performanceId
    endDate
    note
    members {
      ...ClientPortfolioCustomPerformanceMemberFragment
    }
  }
  ${CLIENT_PORTFOLIO_CUSTOM_PERFORMANCE_MEMBER_FRAGMENT}
`

export const CLIENT_PORTFOLIO_CUSTOM_PERFORMANCE_FRAGMENT = gql`
  fragment ClientPortfolioCustomPerformanceFragment on ClientPortfolio {
    customPerformance {
      ...ClientPortfolioCustomPerformanceHolderFragment
    }
  }
  ${CLIENT_PORTFOLIO_CUSTOM_PERFORMANCE_HOLDER_FRAGMENT}
`

export const CLIENT_PORTFOLIO_PERFORMANCE_FRAGMENT = gql`
  fragment ClientPortfolioPerformanceFragment on ClientPortfolio {
    performanceType {
      code
      value
    }
    performanceSource {
      code
      value
    }
    ...ClientPortfolioCustomPerformanceFragment
  }
  ${CLIENT_PORTFOLIO_CUSTOM_PERFORMANCE_FRAGMENT}
`

export const CLIENT_PORTFOLIO_DEFINITION_CONTINUED_FRAGMENT = gql`
  fragment ClientPortfolioDefinitionContinuedFragment on ClientPortfolio {
    id
    #...ClientPortfolioFeesFragment
    ...ClientPortfolioCompositesFragment
    ...ClientPortfolioCompositeMembersFragment
    # CHARACTERISTICS
    ...ClientPortfolioCharacteristicsFragment
    # PERFORMANCE
    ...ClientPortfolioPerformanceFragment
  }
  ${CLIENT_PORTFOLIO_COMPOSITES_FRAGMENT}
  ${CLIENT_PORTFOLIO_COMPOSITE_MEMBERS_FRAGMENT}
  ${CLIENT_PORTFOLIO_CHARACTERISTICS_FRAGMENT}
  ${CLIENT_PORTFOLIO_PERFORMANCE_FRAGMENT}
`

export const CLIENT_PORTFOLIO_DEFINITION_BASIC_FRAGMENT = gql`
  fragment ClientPortfolioDefinitionBasicFragment on ClientPortfolio {
    id
    name
    netOfFees
    relatedVehicle {
      vehicle {
        id: fundid
        name
        category {
          code
          value
        }
        product {
          product {
            id
            name
          }
        }
      }
    }
    isComposite
    # to filter all clientportfolios of its client
    client {
      id
      ... on Client {
        plans {
          id
          name
        }
      }
    }
    plan {
      id
      name
      clientPortfolios {
        id
        name
      }
    }
    dataType {
      code
      value
    }
    isActive
    isTest
  }
`
// need or not?
export const CLIENT_PORTFOLIO_DEFINITION_THIRD_FRAGMENT = gql`
  fragment ClientPortfolioDefinitionThirdFragment on ClientPortfolio {
    id
    lists {
      list {
        id
        name
        type
        category {
          code
          value
        }
        subCategory {
          code
          value
        }
      }
    }
    ...ClientPortfolioBenchmarkFragment
  }
`

export const CLIENT_PORTFOLIO_DATA_COLLECTION_FRAGMENT = gql`
  fragment ClientPortfolioDataCollectionFragment on ClientPortfolioDataCollection {
    contactType {
      code
      value
    }
    comment
    contact {
      id
      firstName
      lastName
      email
      employer {
        id
        name
        shortName
      }
    }
    dataRequestTypes {
      code
      value
    }
  }
`

// for baseUrl/clientportfolios/clientportfolio_id
export const CLIENT_PORTFOLIO_DEFINITION_FRAGMENT = gql`
  fragment ClientPortfolioDefinitionFragment on ClientPortfolio {
    ...ClientPortfolioDefinitionBasicFragment
    isComposite # type
    assetClass {
      ...CurrentAssetClassValueFragment
    }
    manager {
      id
      name
    }
    plan {
      ...ClientPortfolioPlanFragment
      recordKeeper {
        id
        name
      }
      custodian {
        id
        ... on Org {
          name
        }
      }
    }
    notes
    feeSchedule {
      ...ClientPortfolioFeeScheduleFragment
    }
    datedFees {
      ...ClientPortfolioDatedFeeFragment
    }
    inceptionDate
    serviceStartDate
    endAssetDate
    serviceEndDate
    showAssets
    discretionary {
      code
      value
    }
    baseCurrency {
      code
      value
    }
    # group section
    # comment out for deploy
    styleGroup {
      ... on Group {
        id
        groupName: shortName
      }
    }
    groupPerformanceType {
      code
      value
    }
    # BANK
    bank {
      name
    }
    bankAccount
    # RELATIONS
    empowerRelationships {
      empowerCusip
      empowerPlanNumber
    }
    fidelityRelationship {
      fidelityFundCode
      fidelityPlanCode
      fidelitySkipTrialBalance
    }

    dataCollection {
      ...ClientPortfolioDataCollectionFragment
    }
    clientSpecificDataCollectionFields {
      nameOverride
      questionnaireCharacteristicsRequired
      questionnaireHoldingsRequired
      questionnaireCashFlowRequired
      questionnaireSummaryCashFlowRequired
      showFeeCollectOption
      questionnaireFeesRequired
    }
    #lists {
    #  id
    #  name
    #  type
    #}
    #...ClientPortfolioBenchmarkFragment
  }
  ${CLIENT_PORTFOLIO_DEFINITION_BASIC_FRAGMENT}
  ${CURRENT_ASSET_CLASS_VALUE_FRAGMENT}
  ${CLIENT_PORTFOLIO_BENCHMARK_FRAGMENT}
  ${CLIENT_PORTFOLIO_PLAN_FRAGMENT}
  ${CLIENT_PORTFOLIO_DATA_COLLECTION_FRAGMENT}
  ${CLIENT_PORTFOLIO_FEE_SCHEDULE_FRAGMENT}
  ${CLIENT_PORTFOLIO_DATED_FEE_FRAGMENT}
  #${CLIENT_PORTFOLIO_CHARACTERISTICS_FRAGMENT}
`

export const CLIENT_PORTFOLIO_IS_COMPOSITE_FRAGMENT = gql`
  fragment ClientPortfolioIsCompositeFragment on ClientPortfolio {
    id
    name
    isActive
    isComposite
    ... @include(if: $showMembers) {
      compositeMembers {
        startDate
        endDate
        member {
          id
        }
      }
    }
  }
`

export const CLIENT_PORTFOLIOS_FOR_CLIENT_FRAGMENT = gql`
  fragment ClientPortfoliosForClientFragment on ClientPortfolio {
    plan {
      id
      name
    }
    ...ClientPortfolioIsCompositeFragment
  }
  ${CLIENT_PORTFOLIO_IS_COMPOSITE_FRAGMENT}
`

export const PORTFOLIO_DATA_TYPE_FRAGMENT = gql`
  fragment PortfolioDataTypeFragment on PortfolioDataTypeMapItem {
    code
    composite
    order
  }
`

export const CLIENT_PORTFOLIO_MANAGER_FEES_FRAGMENT = gql`
  fragment ClientPortfolioManagerFeesFragment on ClientPortfolio {
    id
    feeSchedule {
      accountSize
      date
      managementFee
    }
    datedFees {
      date
      totalExpense
      annualizedSubAdvisedPctOfApplicableSubadvisedAssets
      annualizedSubAdvisedPctOfTotalPortfolio
      annualizedWrapPctOfApplicableWrappedAssets
      annualizedWrapPctOfTotalPortfolio
    }
    relatedVehicle {
      vehicle {
        id: fundid
        product {
          product{
            id
            name
          }
        }
      }
    }
  }
`
