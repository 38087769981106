import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { compact, filter, flatten, get, groupBy, sortBy } from 'lodash';
import React, { Component } from 'react';
import { Col, DropdownMenu, Form, FormGroup, Input, ListGroup, ListGroupItem, Row, UncontrolledDropdown } from 'reactstrap';
import { Plan, ReportCategoryCode, ReportCategoryLookup, useClientAllReportsQuery } from '../../../__generated__/graphql';

interface ReportPickerProps {
  clientId: number
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (reportId:number) => void
}

interface ReportSelection {
  id: number
  name: string
  category?: ReportCategoryLookup | null
}

const ReportCategoryMap:Record<ReportCategoryCode, string> = {
  [ReportCategoryCode.FPROF]: "Fund Profile",
  [ReportCategoryCode.EXEC]: "Executive Summary"
}

const ReportPicker: React.FC<ReportPickerProps> = ({ clientId, title, children, dropdownClass, onClick }: ReportPickerProps) => {
  const { data } = useClientAllReportsQuery({
    variables: { id: clientId }
  })

  const plans:Plan[] = get(data, 'org.plans', [])
  const reports = compact(flatten(plans.map((p) => p?.report || [])))

  let filteredReports = sortBy(filter(reports, (p) => p.active), 'order')

  return (
    <UncontrolledDropdown className={classNames("headline-dropdown product-picker-dropdown", dropdownClass)}>
      {children}
      <DropdownMenu>
        <h5>{title}</h5>
        <ReportSearch key={reports.length} data={filteredReports} onClick={onClick} />
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

interface ReportSearchProps {
  data:ReportSelection[]
  onClick: (reportId:number) => void
}

export class ReportSearch extends Component<ReportSearchProps> {
  state = {
    reports: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredReports = filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p.name.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      reports: filteredReports
    })
  }

  render() {
    let groupedReports = groupBy(this.state.reports, (r) => r?.category?.code)

    const orderedCategories = Object.keys(ReportCategoryMap) as Array<keyof typeof ReportCategoryMap>

    return (
      <>
        <Row>
          <Col sm="12" md="6">
            <Form>
              <FormGroup row className="relative m-0">
                <Input
                  type="text"
                  placeholder="Find Report by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mt-2 report-picker-list pb-2">
            { orderedCategories.map((reportCategory) => {
              return <ReportCategoryListing
                key={`report-list-category-${reportCategory}`}
                reports={this.state.reports}
                category={reportCategory}
                onClick={this.props.onClick}
              />
            })}
          </Col>
        </Row>

      </>
    )
  }
}

interface ReportCategoryListingProps {
  reports: ReportSelection[]
  category: ReportCategoryCode
  onClick: (reportId:number) => void
}

const ReportCategoryListing = ({ reports, category, onClick }:ReportCategoryListingProps) => {
  const filteredReports = reports.filter((r) => r.category?.code === category).sort((a, b) => a.name.localeCompare(b.name))
  if (filteredReports.length === 0) { return <></> }

  const reportTitle = ReportCategoryMap[category]
  return (
    <React.Fragment key={`report-list-category-${category}`}>
      <h5 className='mb-1 mt-2'>{ reportTitle }</h5>
      <ListGroup className="headline-dropdown-links m-0 ml-3">
        { filteredReports.map((report) => {
          if (!report) { return <></> }

          return (
            <ListGroupItem
              tag="a"
              key={`pp-report-${report?.id}`}
              onClick={() => onClick(report.id)}
            >
              {report?.name}
            </ListGroupItem>
          )
        })}
      </ListGroup>
    </React.Fragment>
  )
}

export default ReportPicker