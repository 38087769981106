import { Maybe, MeQuery, Person, User } from '../__generated__/graphql'

export const CALLAN_FIRM_ID = 244

export const getPersonFullName = (
  person: Person | null | undefined,
  defaultVal: string = "-"
) => {
  if (!person) {
    return defaultVal
  }
  const { firstName, lastName } = person

  if (!firstName || !lastName) {
    return firstName || "" + lastName || "" || defaultVal
  }
  return firstName + " " + lastName
}

export const isCallanUser = (userData:MeQuery | User) => {
  // console.log("isCallanUser is deprecated, move to using auth params of view:all_managers or another relevant permission")
  let firms: Maybe<number>[] | undefined = []
  if("me" in userData){
    firms = userData?.me?.appMetadata?.firms || undefined
  } else if("appMetadata" in userData){
    firms = userData?.appMetadata?.firms || undefined
  }

  return firms && firms.includes(CALLAN_FIRM_ID)
}