import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official"
import { cloneDeep, compact, has, merge, remove, set, startCase } from "lodash"
import moment from "moment"
import { useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Table, UncontrolledTooltip } from "reactstrap"

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from "classnames"
import iassign from 'immutable-assign'
import { AttributionChart, AttributionData, AttributionPerformancePeriod, AttributionSeriesDataPoint, AttributionTable, AttributionTableRow, ComponentExportType, ComponentOverrideSettings, ComponentPeriodType, ComponentType, LayoutSectionType, ListsForOrgFragment, ManagerPerformancePeriodicity, NewReportAttributionDataQuery, ReportAttributionDataQuery, ReportAttributionDataQueryVariables, ReportAttributionFetchFragment, useListSimpleQuery, useNewReportAttributionDataQuery, useReportAttributionDataQuery } from "../../../__generated__/graphql"
import { DATE_DISPLAY_FORMAT } from "../../../helpers/constant"
import { excludePropertyArray } from "../../../helpers/object"
import { convertComponentForMutation } from "../../../helpers/report"
import { ApprovalsReportDisplay } from "../Shared/ReportApprovals"
import { AggregatedComponentProps, AggregatedExportSettingsProps, BaseComponent, ErrorComponent, FootnotableComponent, IndividualComponentProps, LoadingComponent, OptionsModal, ReportDisplayType, TemplateComponent, ToolbarProps } from "../Shared/ReportComponent"
import { ReportDataPoint } from '../Shared/ReportDataPoint'
import { AggregatedReportEditProps, ReportEditField, ReportEditHeading, ReportEditInfoFields, ReportEditInstances, SimpleReportEdit } from "../Shared/ReportEdit"


const Attribution: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportAttributionFetchFragment>
      expectedTypename={"AttributionSettings"}
      reactComponent={AttributionDisplayWrapper}
      {...props}
    />
  )
}

const fetchDisplayData = (data: NewReportAttributionDataQuery | ReportAttributionDataQuery, view: ReportDisplayType, usePreview: boolean): AttributionData => {
  if(has(data, 'componentPreviewData')) {
    const previewData = data as NewReportAttributionDataQuery
    return previewData?.componentPreviewData as AttributionData
  }

  const previewData:ReportAttributionDataQuery = data as ReportAttributionDataQuery

  if ((view === ReportDisplayType.Draft || view === ReportDisplayType.External) && previewData?.components?.[0]?.draftData) {
    return previewData?.components?.[0]?.draftData as AttributionData
  } else if (usePreview && previewData?.components?.[0]?.previewData) {
    return previewData?.components?.[0]?.previewData as AttributionData
  }

  return previewData.components?.[0]?.liveData as AttributionData
}

const AttributionDisplayWrapper: React.FC<IndividualComponentProps<ReportAttributionFetchFragment>> = ({component, auth, settings, selected, handleSelect, editMode, sectionNumber, componentNumber, report, setEditedDraftLayout, editedDraftLayout, setSelectedComponentId, view, hideSingleExport, overwriteDate, portfolioId, updateSettings: parentUpdateSettings }) => {
  const name = component.name || ""
  const componentId = component.id

  const asOfDate = moment(overwriteDate || settings.date).format(DATE_DISPLAY_FORMAT)

  const [initSettings, setInitSettings] = useState(settings)
  const [hoveredRow, setHoveredRow] = useState<number | null>(null)
  const [optionsModalOpen, setOptionsModalOpen] = useState(false)
  const [optionsSettingUsed, setOptionsSettingUsed] = useState(false)

  useEffect(()=>{
    if(editMode === false && initSettings !== settings) {
      setInitSettings(settings)
    }
  }, [editMode]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateSettings = (newSettings: ReportAttributionFetchFragment) => {
    parentUpdateSettings(newSettings)
    setOptionsSettingUsed(true)
    setOptionsModalOpen(false)
  }

  // Only update this when editMode changes, otherwise the options modal will overwrite the initSettings

  const usePreview = (initSettings !== settings || optionsSettingUsed)
  const useNew = component.id < 1
  let usedSettings:{ [name: string]: any; } = {}

  if(usePreview || useNew) {
    let convertedSettings = excludePropertyArray(convertComponentForMutation(settings, ComponentType.Attribution), ["__typename"])

    usedSettings = {
      attribution: {
        ...convertedSettings,
      }
    }
  }

  const queryVariables: ReportAttributionDataQueryVariables = {
    ids: [component.id],
    liveView: (view === ReportDisplayType.Live) && !usePreview,
    draftView: (view === ReportDisplayType.Draft || view === ReportDisplayType.External) && !usePreview,
    preview: usePreview,
    settings: usedSettings,
  }

  const { loading: newLoading, data: newData, error: newError, stopPolling: newStopPolling, startPolling: newStartPolling, refetch: newRefetch  } = useNewReportAttributionDataQuery({
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    skip: !useNew
  })

  const { loading: baseLoading, data: baseData, error: baseError, stopPolling: baseStopPolling, startPolling: baseStartPolling, refetch: baseRefetch  } = useReportAttributionDataQuery({
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    skip: useNew
  })

  let loading, error, refetch, startPolling
  if(useNew) {
    loading = newLoading
    error = newError
    refetch = newRefetch
    startPolling = newStartPolling
  } else {
    loading = baseLoading
    error = baseError
    refetch = baseRefetch
    startPolling = baseStartPolling
  }

  if(loading) return <LoadingComponent name={name} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
  if(error) return <ErrorComponent name={name} error={error?.message} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>

  const data = useNew ? newData : baseData

  // Stop polling for the other type
  if(useNew){
    baseStopPolling()
  } else {
    newStopPolling()
  }

  if (data) {
    const displayedData = fetchDisplayData(data, view, usePreview)

    if(displayedData && displayedData.status !== "Pending" && !loading || error){
      if(useNew){
        newStopPolling()
      } else {
        baseStopPolling()
      }
    }

    let tooltipProps:ToolbarProps = {}
    const attributionOverrideExportSettings:{ startDate?: string, period?: ComponentPeriodType | null } = {}

    if(componentNumber === -1){
      tooltipProps = {'exportOptions': {
        name: name,
        slides: [{
          title: name,
          sections: [{
            components: [componentId],
            type: LayoutSectionType.SingleColumnSection
          }]
        }],
        settings: {
          live: view === ReportDisplayType.Live,
          footerName: report?.exportSettings?.footerName || report?.client?.name
        }
      }}

      if (component.exportTypes?.includes(ComponentExportType.XLSX)) {
        tooltipProps['exportExcelOptions'] = {
          name: name,
          componentId: component.id,
          settings: {
            live: view === ReportDisplayType.Live
          }
        }
      }

      if (has(usedSettings,'attribution.reportingPerformancePeriod')) {
        if (usedSettings.attribution.reportingPerformancePeriod === AttributionPerformancePeriod.Trailing) {
          set(attributionOverrideExportSettings, 'period', usedSettings.attribution.attributionPeriod)
        } else {
          set(attributionOverrideExportSettings, 'startDate', usedSettings.attribution.startDate)
        }

        set(tooltipProps, 'exportOptions.settings.componentOverrideSettings', {
          attribution: [{
            componentId: componentId,
            ...attributionOverrideExportSettings
          }]
        })

        if (component.exportTypes?.includes(ComponentExportType.XLSX)) {
          set(tooltipProps, 'exportExcelOptions.settings.componentOverrideSettings', {
            attribution: {
              ...attributionOverrideExportSettings
            }
          })
        }
      }
    }


    tooltipProps['openOptionsModal'] = (view !== ReportDisplayType.External || auth.checkPermissions(["edit:product_analysis"])) ? () => setOptionsModalOpen(true) : undefined

    const tableData = displayedData?.table
    const chartData = displayedData?.chart

    return (
      <TemplateComponent
        name={name}
        componentTypeName='Attribution'
        rightText={`As of ${asOfDate}`}
        selected={selected}
        onClick={handleSelect}
        tooltipProps={tooltipProps}
        editMode={editMode}
        sectionNumber={sectionNumber}
        report={report}
        setEditedDraftLayout={setEditedDraftLayout}
        editedDraftLayout={editedDraftLayout}
        componentNumber={componentNumber}
        componentId={componentId}
        setSelectedComponentId={setSelectedComponentId}
        view={view}
        auth={auth}
        portfolioId={portfolioId}
        hideExport={hideSingleExport}
      >
        <AttributionOptionsModal
          settings={settings}
          optionsModalOpen={optionsModalOpen}
          setOptionsModalOpen={setOptionsModalOpen}
          updateSettings={updateSettings}
        />
        <Row className="no-gutters">
          {/* <Col sm="12"> // Removed for the time being until new components are created.
            <PerformanceComparisonSupporting
              settings={settings}
              portfolio={portfolio}
              dcPortfolio={dcPortfolio}
            />
          </Col> */}
          { displayedData && chartData && (
            <AttributionGraph
              key={`attribution-graph-${componentId}`}
              editMode={editMode}
              chartData={chartData}
              hoveredRow={hoveredRow}
              errors={compact(displayedData?.error) || []}
            />
          )}
        </Row>
        <Row className="no-gutters">
          <Col sm="12">
            { displayedData && tableData && (
              <AttributionDataTable
                key={`attribution-table-${componentId}`}
                hoveredRow={hoveredRow}
                setHoveredRow={setHoveredRow}
                tableData={tableData}
                draftView={view === ReportDisplayType.Draft}
                componentId={componentId}
              />
            )}
          </Col>
        </Row>
      </TemplateComponent>
    )
  }

  return <ErrorComponent name={name} error={"No data returned"} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>

}

interface AttributionGraphProps {
  chartData: AttributionChart
  editMode: boolean
  errors: string[]
  hoveredRow: number | null
}

const AttributionGraph: React.FC<AttributionGraphProps> = ({ editMode, chartData, hoveredRow, errors }) => {
  const graphRef = useRef<{ chart: Highcharts.Chart; container: React.RefObject<HTMLDivElement>; }>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.chart.reflow()
      graphRef.current.chart.reflow()
      graphRef.current.chart.reflow()
    }
  }, [editMode])

  const chartOptions = merge(JSON.parse(chartData.config || '{}'))

  if (hoveredRow && chartData.additionalSeries) {
    const newSeries = chartData.additionalSeries.find((series) => series?.assetMixNum === hoveredRow)

    if (newSeries) {
      const strippedSeries = excludePropertyArray(newSeries, ["__typename"])

      // convert series data points from object to 2 element array
      const convertedSeries = strippedSeries.series.map((series:any) => (
        iassign(
          series,
          (s) => s.data,
          (data) => {
            return data.map((point:AttributionSeriesDataPoint) => ([ point.date, point.value ]))
          }
        )
      ))

      chartOptions.series = convertedSeries
    }
  }



  const graph = useMemo(() => {
    return (
      <Col sm="12">
        <div className="form-section-title headline underline small-font-size mb-0 pb-0">
          {chartData.title}
          { errors && errors.length > 0 && (
            <>
              <div className="footnote-icon d-inline" ref={tooltipRef}>
                <FontAwesomeIcon
                  icon={"info-circle" as IconName}
                  size="sm"
                  className="text-danger"
                />
              </div>
              <UncontrolledTooltip placement='top' target={tooltipRef} delay={200} autohide={false}>
                { errors?.join(', ')}
              </UncontrolledTooltip>
            </>
          )}
        </div>
        <HighchartsReact
          ref={graphRef}
          options={chartOptions}
          highcharts={Highcharts}
          containerProps={{ style: { height: "400px"  }}}
        />
      </Col>
    )
  }, [chartOptions, chartData.title])

  return graph
}

interface AttributionDataTableProps {
  tableData: AttributionTable
  hoveredRow: number | null
  setHoveredRow: (row: number | null) => void
  draftView: boolean
  componentId: number
}

const AttributionDataTable: React.FC<AttributionDataTableProps> = ({tableData, hoveredRow, setHoveredRow, draftView, componentId}) => {
  const columns = tableData.columns || []
  const tableRows = compact(tableData.rows) || []

  return (
    <Col sm="12">
      <div className="form-section-title headline underline small-font-size mb-0 pb-0">
        {tableData.title}
      </div>

      <Table hover className="report-table-attribution-table">
        <thead>
          <tr>
            <td></td>
          {
            columns.map((column, idx) => (
              <td className="pl-0 text-center" key={`attribution-column-header-${idx}`}>
                { column }
              </td>
            ))
          }
          </tr>
        </thead>
        <tbody>
          {
            tableRows.map((row: AttributionTableRow, idx) => (
                <tr
                  className={classNames({
                    "report-tr-title": true,
                    "report-tr-total": row.total,
                  })}
                  key={`attribution-row-${componentId}-${row.rowId}`}
                  onMouseEnter={() => setHoveredRow(row.rowId)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td className="pl-0 text-right" >
                    <FootnotableComponent>
                      { row.name }
                    </FootnotableComponent>
                  </td>
                  {
                    columns && columns?.map((column, cidx) => {
                      // const column = columns[idx]
                      let dataPoint = undefined
                      if(row.columnData && row.columnData[cidx]){
                        dataPoint = row.columnData[cidx]
                      }
                      return(
                        <ReportDataPoint
                          key={`attribution-cell-${componentId}-${row.rowId}-${cidx}`}
                          dataPoint={dataPoint || undefined}
                          draftView={draftView}
                        />
                      )
                    })
                  }
                </tr>
              )
            )
          }
        </tbody>
      </Table>
    </Col>

  )

}

const AttributionReportingPeriodFields:ReportEditField[] = [
  {
    property: "attributionReportingPerformancePeriod",
    label: "",
    type: "radio",
    options: [{
      code: "Trailing",
      value: "Trailing",
    },{
      code: "StartingOn",
      value: "Starting On",
    }],
    subClasses: {
      inputWrapperClasses: "col-sm-12 radio-input-with-divider mt-1 mb-2 w-100",
    },
  }
]

const AttributionPerformanceFields = [
  {
    property: "performancePeriodicity",
    label: "Returns used",
    type: "select",
    optionSource: "ManagerPerformancePeriodicity",
    required: true
  },
  {
    property: "attributionPerformanceType",
    label: "Performance type",
    type: "select",
    optionSource: "FeeType",
    required: true,
  }
]

const AttributionAdditionalReportingFields:ReportEditField[] = [
  {
    property: "lineChart",
    label: "Line Chart",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

const AttributionEditFootnoteFields:ReportEditField[] = [
  {
    property: "showTargetFootnote",
    label: "Benchmark",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

const ComponentPeriodOptionsOrder = ['lastMonth', 'lastQuarter', 'yearToDate', 'fiscalYear', 'last1Year','last2Years', 'last3Years','last5Years','last7Years','last10Years','last15Years','last20Years','sinceInception']

const getColumnsByPeriod = (settings: ReportAttributionFetchFragment, inOptionsModal:boolean = false):ReportEditField[] => {
  switch (settings.attributionReportingPerformancePeriod) {
    case AttributionPerformancePeriod.Trailing:
      let newOptions = Object.values(ComponentPeriodType).map((period) => ({
        code: period,
        value: startCase(period)
      }))
      newOptions = newOptions.sort((a, b) => ComponentPeriodOptionsOrder.indexOf(a.code) - ComponentPeriodOptionsOrder.indexOf(b.code))

      if (settings.performancePeriodicity === ManagerPerformancePeriodicity.Quarterly) {
        remove(newOptions, (option) => option.code === 'lastMonth')
      }

      return [{
        property: "attributionPeriod",
        label: "Period",
        type: "select",
        options: newOptions,
        sortOptions: false
      }]

    case AttributionPerformancePeriod.StartingOn:
      let subType = 'month'
      if (settings.performancePeriodicity === ManagerPerformancePeriodicity.Quarterly) {
        subType = 'quarter'
      }

      let subClasses = {}
      if (inOptionsModal) {
        subClasses = {
          inputClasses: "in-modal",
        }
      }

      return [
        {
          property: "startDate",
          label: "Start on and include",
          type: "date",
          subtype: subType,
          required: true,
          subClasses
        }
      ]
    default:
      return []
  }
}

interface AttributionOptionsModalProps {
  settings: ReportAttributionFetchFragment
  optionsModalOpen: boolean
  setOptionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  updateSettings: (settings: ReportAttributionFetchFragment) => void
}

const AttributionOptionsModal = (props: AttributionOptionsModalProps) => {
  const { settings, optionsModalOpen, setOptionsModalOpen, updateSettings } = props
  const [settingsCopy, setSettingsCopy] = useState<ReportAttributionFetchFragment>(settings)


  let reportingPeriodColumns = [...AttributionReportingPeriodFields, ...getColumnsByPeriod(settingsCopy, true)]

  const handleSubmit = () => {
    if(updateSettings) updateSettings(settingsCopy)
    setOptionsModalOpen(false)
  }

  const handleInputChange = (value:any, property: string) => {
    setSettingsCopy((prevState) => {
      let newState = cloneDeep(prevState)
      set(newState, property, value)
      return newState
    })
  }

  return (
    <OptionsModal show={optionsModalOpen} setShow={setOptionsModalOpen} onSubmit={handleSubmit}>

      <SimpleReportEdit
        name={"Reporting Period"}
        retractable={true}
        fields={reportingPeriodColumns}
        handleInputChange={handleInputChange}
        currentState={settingsCopy}
      />
    </OptionsModal>
  )
}


export const AttributionEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, reportId}) => {
  const settings = component.draftSettings as ReportAttributionFetchFragment
  const { data: listData } = useListSimpleQuery({
    variables: {id: settings.list?.id || 0},
    skip: !settings || !settings.list?.id,
    errorPolicy: "all",
  })

  if(!settings){
    return(
      <></>
    )
  }

  let reportingPeriodColumns = [...AttributionReportingPeriodFields, ...getColumnsByPeriod(settings)]

  const ownedComponent = component.owner?.id === user?.person?.id

  let benchmarkOptions = settings?.clientPortfolio?.performanceTargetMap?.map((targetMap) => (
    {
      code: targetMap?.target?.targetId || null,
      value: targetMap?.target?.name || '',
    }
  ))
  let infoColumns = [...ReportEditInfoFields(handleInputChange), {
    property: "clientPortfolio",
    label: "Portfolio",
    type: "searchSelect",
    options: [{ code: "Portfolios", value: "Portfolios" }]
  },
  {
    property: "target.targetId",
    label: "Benchmark",
    type: "select",
    options: benchmarkOptions,
    required: true
  }]

  const listIndex = infoColumns.findIndex((column) => column.property === "list.id")
  set(infoColumns, `[${listIndex}].filterOptionsMethod`, (list:ListsForOrgFragment) => list?.subCategory?.value === "Attribution")

  return (
    <>
      <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={"Attribution"}
        componentType={ComponentType.Attribution}
        reportId={reportId}
        handleInputChange={handleInputChange}
      />
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <ReportEditInstances component={component}/>
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      />
      <SimpleReportEdit
        name={"Performance"}
        retractable={true}
        fields={AttributionPerformanceFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"Additional Reporting"}
        retractable={true}
        fields={AttributionAdditionalReportingFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"Reporting Period"}
        retractable={true}
        fields={reportingPeriodColumns}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"footnotes"}
        retractable={true}
        fields={AttributionEditFootnoteFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
    </>
  )
}

export const AttributionExportSettings = (props: AggregatedExportSettingsProps): ComponentOverrideSettings =>  {
  // const { component } = props
  // const reportState = props.reportState as componentState
  // const tab = reportState?.tab
  // const selectedDrillDown = reportState?.selectedDrillDown

  // return { attribution: [{
  //   componentId: component?.id,
  //   startDate:
  // }]}
  // return {PerformanceComparison: [
  //   {
  //     componentId: component?.id,
  //     selectedDate: tab?.value,
  //     drillDownGroup: parseInt(selectedDrillDown || "") || undefined
  //   }
  // ]}
  return {}
}

export default Attribution