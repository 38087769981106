import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { compact, find, sortBy } from 'lodash';
import React, { Component, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { ListsForOrgFragment, useListsForOrgQuery } from '../../../__generated__/graphql';

interface ListPickerProps {
  selectedListId: number | undefined
  dropdownClass?: string
  editMode: boolean
  onClick: (listId:number) => void
  clientId: number
  filterMethod?: (list:ListsForOrgFragment) => boolean
}

export interface ListSelection {
  id: number
  name?: string | null
}

const ListPicker: React.FC<ListPickerProps> = ({ clientId, dropdownClass, onClick, selectedListId, editMode, filterMethod }: ListPickerProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const {data, loading, error} = useListsForOrgQuery({variables: {id: clientId}})

  const toggle = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    if(editMode) {
      event?.stopPropagation()
      setDropdownOpen(!dropdownOpen)
    }
  }

  const selectList = (list:ListsForOrgFragment) => {
    toggle()
    onClick(list.id)
  }

  const display = (text:string) => {
    return (
      <Row className="w-100 no-gutters form-group">
        <Label
          className={"col-form-label col-sm-4"}
          for={`1-listselect`}
          id={"list.id"}
        >
          <div
            className={"d-flex w-100 justify-content-start tooltip-icon"}
          >
            List
            <FontAwesomeIcon
              icon="external-link"
              size="sm"
              onClick={(e) => {e.stopPropagation(); window.open(`/lists/${selectedListId}`,'_blank')}}
            />
          </div>
        </Label>
        <div className={"col-sm-8"}>
          <div className={classNames("fake-input form-control form-control-sm", {"disabled": !editMode})}>
            {text}
          </div>
        </div>
      </Row>
    )
  }

  if (loading) {
    return display("Loading ...")
  }
  if(error) {
    return display("Error")
  }
  if(!data?.lists) {
    return display("No lists Retuned")
  }

  console.log('lists', data.lists)
  let filteredLists = data.lists
  if (filterMethod) {
    filteredLists = compact(data.lists).filter(filterMethod)
  }
  const options = sortBy(compact(filteredLists), ["name", "id"])
  const selectedList = find(options, {id: selectedListId})
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={classNames("form-dropdown list-picker-dropdown", dropdownClass)}>
      <DropdownToggle caret className={classNames('data-dropdown', {'hide-caret': !editMode})} title={selectedList?.name || "No List Selected"}>
        {display(selectedList?.name || "No List Selected")}
      </DropdownToggle>
      <DropdownMenu>
        <ListSearch data={options} onClick={selectList} selectedListId={selectedListId}/>
      </DropdownMenu>
    </Dropdown>
  )

}

interface ListSearchProps {
  data: ListsForOrgFragment[]
  onClick: (list:ListsForOrgFragment) => void
  selectedListId: number | undefined
}

export class ListSearch extends Component<ListSearchProps> {
  render() {
    return (
      <>
        <ListGroup className="headline-dropdown-links" onClick={(event)=>event.stopPropagation()}>
          { this.props.data?.map((list, idx) => {
            if (!list) { return <React.Fragment key={idx}></React.Fragment> }
            const selected = this.props.selectedListId === list.id
            return (
              <ListGroupItem tag="a" key={idx} onClick={() => this.props.onClick(list)} className={classNames("px-3", {"active": selected})}>
                <Row className="w-100 no-gutters"><Col>{list.name}</Col></Row>
                <Row className="w-100 no-gutters">
                  <Col xs={4}>
                    <div className="text-uppercase text-gray-70">Type</div>
                    {list.type}
                  </Col>
                  <Col xs={2}>
                    <div className="text-uppercase text-gray-70">Id</div>
                    {list.id}
                  </Col>
                  <Col xs={6}>
                    <div className="text-uppercase text-gray-70">Subcat</div>
                    {list.subCategory?.value}
                  </Col>
                </Row>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </>
    )
  }
}

export default ListPicker