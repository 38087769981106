import React, { useEffect, useState } from "react"

interface MiddleEllipsisProps {
  text?: string
}

const truncate = (text: string) =>{
  const textLimit = Math.ceil(30)
  if (!text) return text;
  if (textLimit < 1) return "";
  if (text.length < textLimit) return text;
  if (textLimit === 1) return text.substring(0,1) + '...';
  /* extract the last word */
  var lastPart = text.slice(textLimit/2);
  /* if last word is longer than half of the max available space
     cut it from the left */
  var lastPartLimit = Math.ceil(textLimit / 2);
  if(lastPart.length > lastPartLimit) {
      var truncatedLastPart = lastPart;
      /* Try to find a dash and cut the last word there */
      var lastDashPart = text.slice( text.lastIndexOf('-')+1 );
      if(lastDashPart.length < lastPartLimit){
          truncatedLastPart = lastDashPart;
      }
      /* If the last part is still too long or not available cut it anyway */
      if(truncatedLastPart.length > lastPartLimit) {
          var lastIndex = lastPart.length - lastPartLimit;
          truncatedLastPart = lastPart.substring( lastIndex );
      }
      lastPart = truncatedLastPart;
  }
  const dots = '... ';
  const firstPartLength = textLimit - lastPart.length - dots.length;
  return text.substring(0, firstPartLength) + dots + lastPart;
}

const MiddleEllipsis:React.FC<MiddleEllipsisProps> = ({text}) => {
  let [cutPoint, setCutPoint] = useState(Math.ceil((text || '').length * 0.5))
  let [displayText, setDisplayText] = useState(truncate(text || ''))

  const calculateCutPoint = () => {
    if(!text || text.length === 0){
      return
    }
    let testCutPoint = cutPoint
    let testFirstHalf = text.substring(0, testCutPoint)
    let testSecondHalf = text.substring(testCutPoint)
    while(getTextWidth(testFirstHalf) < getTextWidth(testSecondHalf)){
      testCutPoint += 1
      testFirstHalf = text.substring(0, testCutPoint)
      testSecondHalf = text.substring(testCutPoint)
    }
    setCutPoint(testCutPoint)
  }

  useEffect(() => {
    calculateCutPoint()
    truncate(text || '')
  }, [text])

  if(!text || text.length === 0){
    return <></>
  }


  let firstHalf = text.substring(0, cutPoint)
  let secondHalf = text.substring(cutPoint)

  if(secondHalf[0] === " "){
    firstHalf = firstHalf.concat(" ")
  }
  return (
    <div>{displayText}</div>
    // <span
    //   className="middle-ellipsis"
    //   data-content-start={firstHalf}
    //   data-content-end={secondHalf}
    // />
  )
}


function getTextWidth(text:string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if(!context) return 0

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
}

export default MiddleEllipsis