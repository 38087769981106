import { QueryResult } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import classnames from 'classnames'
import { compact } from "lodash"
import React, { Component } from 'react'
import { RouteComponentProps, Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { BreadcrumbProps, Col, Container, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledAlert, UncontrolledDropdown } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { ClientDetailsDocument, ClientDetailsQuery, ClientDetailsQueryVariables, ClientOverviewFragment, ReportCategoryCode } from "../../__generated__/graphql"
import { registerRecentClient } from '../../helpers/session'
import { appProps } from '../../queries/extended/appProps'
import { CrumbRoute } from '../../routes/crumb-route'
import ErrorDisplay from '../Shared/ErrorDisplay'
import { WithTopNav } from '../ui/LayoutWrapper'
import LoadingOverlay from '../ui/Loading'
import ClientDocumentList from './ClientDocumentList'
import ClientFund from './ClientFund'


interface ClientRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.managerId
    clientId: string
    typeOrReportId: string
    type: string
  }> {}


const getActiveTab = (props: any, url: string) => {
  if (props.match.url.indexOf(`${url}/portfolios`) === 0) {
    return 'Portfolios'
  }
  if (props.match.url.indexOf(`${url}/documents`) === 0) {
    return 'Documents'
  }
  if (props.match.url.indexOf(`${url}/portfolios`) === 0) {
    return 'Portfolios'
  }
  return 'Menu'
}

interface ClientOverviewDetailFragment {
  data: ClientOverviewFragment
}

const ClientDetailComponent: React.FC<appProps &
  ClientRouteProps &
  ClientOverviewDetailFragment> = props => {
  const history = useHistory()
  const { match } = props
  const {data} = props
  if (!data) {
    return (
      <Container fluid>
        <UncontrolledAlert color="danger">
          <h4>404: Org Not Found</h4>
          <p>No Org exists with this id.</p>
        </UncontrolledAlert>
      </Container>
    )
  }

  // if (data.__typename === "Manager"){
  //   history.replace(`/managers/${data.id}`)
  // }

  if (data.__typename !== 'Client') {
    return (
      <Container fluid>
        <UncontrolledAlert color="danger">
          <h4>400: Org Not Supported</h4>
          <p>Org type {data.__typename} is not currently supported</p>
        </UncontrolledAlert>
      </Container>
    )  }

  const { typeOrReportId, type } = match.params
  const urlWithoutTypeInital = type
    ? match.url.slice(0, -type.length - 1)
    : match.url
  const urlWithoutType = typeOrReportId
    ? urlWithoutTypeInital.slice(0, -typeOrReportId.length - 1)
    : urlWithoutTypeInital
  registerRecentClient(data.id, data.name)

  const activePlans = compact(
    data.plans?.filter(
      (plan) =>
        plan?.report?.some((report) => report?.active && report?.category?.code === ReportCategoryCode.FPROF)
    ) || []
  )
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline">{data.name}</h2>
            <Nav className="sub-nav sub-nav-primary collapsed" tabs role="group" aria-label="Client Subpage">
              <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                <DropdownToggle caret>
                  { getActiveTab(props, urlWithoutType) }
                </DropdownToggle>
                <DropdownMenu>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/documents`) === 0 || match.url.indexOf(`${urlWithoutType}/reports`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/documents`)}
                    >
                      Documents + Reports
                    </NavLink>
                  </NavItem>
                  {activePlans.map((plan)=> {
                    const report = plan?.report?.find((report) => report?.active && report?.category?.code === ReportCategoryCode.FPROF)
                    const hasLiveList = !!(report?.liveList && report?.active) || (!!report?.draftList && props.auth.checkPermissions(["view:draft_reports"]))
                    const currentType = isNaN(parseInt(type)) && type !== undefined ? type : (hasLiveList ? report?.id : "documents")
                    return(
                      <NavItem key={plan.id}>
                        <NavLink
                          className={classnames({
                            active: match.url.indexOf(`${urlWithoutType}/${plan.id}`) === 0
                          })}
                          onClick={() => history.push(`${urlWithoutType}/${plan.id}/${currentType}`)}
                        >
                          {plan.shortName || plan.name}
                        </NavLink>
                      </NavItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        <CrumbRoute
          title="documents + reports"
          path={`${urlWithoutType}/:documentType(reports|documents)`}
          render={(params: BreadcrumbProps) => <ClientDocumentList clientId={data.id} {...props} match={params.match} />}
        />
        <CrumbRoute
          title="fund"
          path={`${urlWithoutType}/:planId/:type(\\d+|documents|reports|portfolios|interactions|managerdocs)?`}
          render={(params: BreadcrumbProps) => <ClientFund clientId={data.id} {...props} match={params.match}/>}
        />
        {/* <Redirect to={`${urlWithoutType}/documents`} /> */}
      </Switch>
    </>
  )
}
class QueryClientDetails extends Component<
  ClientRouteProps & Partial<ClientDetailsQueryVariables> & appProps
> {
  render() {
    const id = parseInt(this.props.match.params.clientId)
    const { auth, history, location, match } = this.props
    const newProps = { auth, history, location, match }
    return (
      <Query<ClientDetailsQuery, ClientDetailsQueryVariables>
        query={ClientDetailsDocument}
        variables={{ id }}
        fetchPolicy="cache-and-network"
        notifyOnNetworkStatusChange={true}
      >
        {params => {
          const allProps = { ...params, ...newProps }
          if (!params) {
            return <>Null</>
          }
          return <>{ClientDetailsOverview(allProps)}</>
        }}
      </Query>
    )
  }
}

interface authProps {
  auth: Auth
}

interface ClientDetailsOverviewQueryChildren {
  (
    result: QueryResult<ClientDetailsQuery, ClientDetailsQueryVariables> &
      authProps &
      ClientRouteProps
  ): React.ReactNode
}

const ClientDetailsOverview: ClientDetailsOverviewQueryChildren = function(params) {
  // const client = useApolloClient()
  // useEffect(() => {
  //   // Reset cache as some graphql objects are keyed in such a way as
  //   // objects from different managers would overlap
  //   client.resetStore()
  // })
  const { loading, error, data, history, match, location } = params
  const auth = params.auth as Auth
  const routeProps = { history, match, location }
  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <div>
        <ErrorDisplay error={error}/>
      </div>
    )
  }
  if (!loading && data) {
    return (
      <ClientDetailComponent
        data={data.overview as ClientOverviewFragment}
        auth={auth}
        {...routeProps}
      />
    )
  }
}

export default WithTopNav(QueryClientDetails)
