import { Query } from '@apollo/client/react/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { History, Location } from 'history'
import { compact, find, uniq } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { GET_INTERACTION } from '../../queries/Interaction'
import { FileBasicInfoFragment, GetInteractionQuery, useAddInteractionAssociationMutation, useClientReportDocumentsQuery, useClientReportInteractionsQuery, useDownloadInteractionNoteLazyQuery, useMeQuery, useRemoveInteractionAssociationMutation, useUpdateFileMetadataMutation } from '../../__generated__/graphql'
import { ASSET_CLASS_OPTIONS_DATA, InteractionDisplay, InteractionsSidebar } from '../Org/ManagerInteractions'
import ErrorDisplay from '../Shared/ErrorDisplay'
import PlaceHolder from '../ui/PlaceHolder'


type idProps = {
  clientId: number
  planId: number
  history: History
  location: Location
  auth: Auth
}

const ClientInteractions: React.FC<idProps> = ({ planId, clientId, history, location, auth }) => {
  const [search, setSearch] = useState("")

  const params = useParams() as { id?: string }
  const getInitialInteractionId = (id:string) => {
    const interactionId = id || ""
    if (!interactionId) {
      return "0"
    }else {
      return interactionId
    }
  }

  const [selectedInteractionId, setSelectedInteractionId] = useState(()=>getInitialInteractionId(params.id || ""))
  const [addInteractionsMutation] = useAddInteractionAssociationMutation()
  const [removeInteractionsMutation] = useRemoveInteractionAssociationMutation()
  const [updateFileMutation] = useUpdateFileMetadataMutation()

  const { loading, error, data } = useClientReportInteractionsQuery({
    fetchPolicy: "cache-first",
    variables: { id: clientId }
  })

  const [getInteractionUrl, {loading: urlLoading, data: urlData, error: urlError}] = useDownloadInteractionNoteLazyQuery({
    fetchPolicy: "network-only"
  });

  const { data: documentsData } = useClientReportDocumentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { id: clientId },
    errorPolicy: "all",
  })

  const { loading:userLoading, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || null

  useEffect(()=>{
    let newId = getInitialInteractionId(params.id ||"")
    if(newId !== selectedInteractionId) {
      setSelectedInteractionId(newId)
    }
  }, [params.id, selectedInteractionId])


  const selectInteraction = (id:string) => {
    // setSelectedInteractionId(id)
    if(selectedInteractionId === "" || selectedInteractionId === "0"){
      history.replace(`/clients/${clientId}/${planId}/interactions/${id}`)
    } else {
      history.push(`/clients/${clientId}/${planId}/interactions/${id}`)
    }
  }

  const heading = (
    <>
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="mr-2 pr-3 border-right" onSubmit={e=>e.preventDefault()}>
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
      </div>
    </>
  )

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data && data.org?.__typename === "Client") {
    const plan = find(data.org.plans, ["id", planId])
    const interactions = uniq(compact(plan?.interactions || []))
    const showInternalOpinion = auth.checkPermissions(["view:interactions_private"])
    return (
      <Container fluid>
        {heading}
        <Row>
            <InteractionsSidebar
              auth={auth}
              interactions={interactions}
              assetClasses={ASSET_CLASS_OPTIONS_DATA}
              selectInteraction={(id:string) => selectInteraction(id)}
              selectedInteraction={selectedInteractionId}
              search={search}
              source={"plan"}
              showAssociation={true}
              clientInteractions={true}
            />
            <Query<GetInteractionQuery> query={GET_INTERACTION} variables={{ id: selectedInteractionId, showInternal: showInternalOpinion}} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
              {
                (results) => {
                  if (results.loading) {
                    return (
                      <Col md="8" lg="9" className="pl-1">
                        <div className="pane">
                          <PlaceHolder />
                        </div>
                      </Col>
                    )
                  }

                  if (results.error) {
                    var error=results.error
                    return (
                      <Col md="8" lg="9" className="pl-1">
                        <div className="pane">
                          <UncontrolledAlert color="danger">
                            <h4>Error Fetching Interaction</h4>
                            <ErrorDisplay error={error}/>
                          </UncontrolledAlert>
                        </div>
                      </Col>
                    )
                  }

                  const interaction = results.data?.interaction
                  // fix update inside render warning
                  if (!interaction) {
                    return (
                      <Col md="8" lg="9" className="pl-1">
                        <div className="pane">
                          <Row>
                            <Col>
                              <h3>No Interactions Listed</h3>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )
                   }

                  return(<InteractionDisplay
                    id={selectedInteractionId}
                    editMode={false}
                    notEditable={true}
                    interaction={interaction}
                    setCurrentInteraction={() => null}
                    onChange={() => null}
                    onProductNoteChange={() => null}
                    onProductNoteDelete={() => null}
                    user={user}
                    setEditMode={() => null}
                    refetchQuery={(variables?: Record<string, any> | undefined) => {
                      return results.refetch(variables)
                    }}
                    forceUpdate={false}
                    auth={auth}
                    addInteractionsMutation={addInteractionsMutation}
                    removeInteractionsMutation={removeInteractionsMutation}
                    updateFileMutation={updateFileMutation}
                    associationType="Manager"
                    downloadUrl={getInteractionUrl}
                    urlLoading={urlLoading}
                    urlError={urlError}
                    urlData={urlData}
                    showAssociation={true}
                    searchableDocuments={documentsData?.org?.__typename === "Client" ? documentsData.org.documents as FileBasicInfoFragment[] || undefined : undefined}
                    />)
                }
              }
            </Query>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

export default ClientInteractions