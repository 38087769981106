import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { compact, filter, find, some } from 'lodash'
import React from 'react'
import { Redirect, RouteComponentProps, Switch, useHistory } from 'react-router-dom'
import { BreadcrumbProps, Button, Col, Container, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledDropdown } from 'reactstrap'

import { appProps } from '../../queries/extended/appProps'
import { CrumbRoute } from '../../routes/crumb-route'
import { ClientOverviewFragment, ReportCategoryCode, ReportOverviewFragment } from '../../__generated__/graphql'
import ClientDocumentList from './ClientDocumentList'
import ClientInteractions from './ClientInteractions'
import ClientPortfolioList from './ClientPortfolioList'
import ClientReportDetail from './ClientReportDetail'
import FundManagerDocumentsList from './FundManagerDocumentList'


interface ClientRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.managerId
    planId: string
    type: string
    reportId: string
}> {}

interface ClientOverviewDetailFragment {
  data: ClientOverviewFragment
  clientId: number
}

const getActiveTab = (props: any, url: string) => {
  if (props.match.url.indexOf(`${url}/fund`) === 0) {
    return 'Fund Profile'
  }
  if (props.match.url.indexOf(`${url}/documents`) === 0 || props.match.url.indexOf(`${url}/reports`) === 0) {
    return 'Fund Documents'
  }
  if (props.match.url.indexOf(`${url}/portfolios`) === 0) {
    return 'Manager Data'
  }
  if (props.match.url.indexOf(`${url}/managerdocs`) === 0) {
    return 'Manager Documents'
  }
  return 'Menu'
}

const ClientFund: React.FC<appProps &
  ClientRouteProps &
  ClientOverviewDetailFragment> = props => {
  const history = useHistory()
  // const params = useParams() as { subtype?: string }
  // const match = useRouteMatch() || props.match

  const { match, auth } = props
  const {data} = props

  const { type, planId: planIdOrString, reportId } = match.params
  const planId = parseInt(planIdOrString)
  const urlWithoutType = type
    ? match.url.slice(0, -type.length - 1)
    : match.url

  // let urlWithoutSubtype =
  // params && params.subtype
  //   ? match.url.slice(0, -params.subtype.length - 1)
  //   : match.url

  let fundReports: ReportOverviewFragment[] | undefined
  let plan = find(data.plans, ["id", planId])
  fundReports = filter(plan?.report, { active: true, category: { code: ReportCategoryCode.FPROF } }) as ReportOverviewFragment[]
  // if ((data.report?.length || 0) > 0 && !report){
  //   history.replace(`/clients/${data.id}/${first(data.report)?.id}/${type}`)
  // }
  const firstLiveList = find(fundReports, (fundReport) => !!(fundReport?.liveList && fundReport?.active) || (!!fundReport?.draftList && props.auth.checkPermissions(["view:draft_reports"])))
  const onFundReport = !isNaN(parseInt(type))
  const onReport = type === "reports"
  const currentReportId = onFundReport ? parseInt(type) : firstLiveList?.id
  const currentReport = find(fundReports, ["id", currentReportId])

  return (
    <>
      <Container fluid className='position-relative'>
        <Row>
          <Col>
            <Nav className="sub-nav sub-nav-secondary collapsed" tabs role="group">
              <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                <DropdownToggle caret>
                  { onFundReport ? currentReport?.name : getActiveTab(props, urlWithoutType) }
                </DropdownToggle>
                <DropdownMenu>
                  { compact(fundReports.map((report) => {
                    if(!((report?.liveList && report?.active) || (!!report?.draftList && props.auth.checkPermissions(["view:draft_reports"])))) return null
                    return (
                      <NavItem key={report.id}>
                        <NavLink
                          className={classnames({
                            active: match.url.indexOf(`${urlWithoutType}/${report?.id}`) === 0
                          })}
                          onClick={() => history.push(`${urlWithoutType}/${report?.id}`)}
                        >
                          {report?.name}
                        </NavLink>
                      </NavItem>
                    )
                  }))}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/documents`) === 0 || match.url.indexOf(`${urlWithoutType}/reports`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/documents`)}
                    >
                      Fund Documents + Reports
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/managerdocs`) === 0
                      })}
                      onClick={() => history.push(`${urlWithoutType}/managerdocs`)}
                    >
                      Manager Documents
                    </NavLink>
                  </NavItem>
                  {auth.checkPermissions(["view:interactions"]) &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/interactions`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/interactions`)}
                      >
                        Interactions
                      </NavLink>
                    </NavItem>
                  }
                  { !!firstLiveList &&
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/portfolios`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/portfolios/${currentReportId}`)}
                      >
                        Manager Data
                      </NavLink>
                    </NavItem>
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Col>
        </Row>
        {auth.checkPermissions(["view:all_clients"]) && (onFundReport) &&
          <Button color="blue-link" className="nav-right-link" onClick={() => history.push(`/reports/${currentReportId}`)}>
            <span className="pr-1">Report Editor</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        }
      </Container>
      <Switch>
        { !!firstLiveList &&
          <CrumbRoute
            title="fund"
            path={`${urlWithoutType}/:reportId(\\d+)`}
            render={(params: BreadcrumbProps) => <ClientReportDetail {...props} match={params.match} />}
          />
        }
        { !!firstLiveList && plan &&
          <CrumbRoute
            title="portfolios"
            path={`${urlWithoutType}/portfolios/:reportId(\\d+)`}
            render={(params: BreadcrumbProps) => <ClientPortfolioList {...props} plan={plan} match={params.match} />}
          />
        }
        <CrumbRoute
          title="documents + reports"
          path={`${urlWithoutType}/:documentType(reports|documents)/:reportId(\\d+)?`}
          render={(params: BreadcrumbProps) => <ClientDocumentList {...props} match={params.match} planId={planId} />}
        />
        <CrumbRoute
          title="managerdocs"
          path={`${urlWithoutType}/managerdocs`}
          render={(params: BreadcrumbProps) => <FundManagerDocumentsList {...props} match={params.match} planId={planId} />}
        />
        {auth.checkPermissions(["view:interactions"]) &&
          <CrumbRoute
            title="interactions"
            path={`${urlWithoutType}/interactions/:id([A-Za-z0-9]+)?`}
            render={(params: BreadcrumbProps) => <ClientInteractions {...props} planId={planId} />}
          />
        }
        <Redirect to={`${urlWithoutType}/${!!firstLiveList ? currentReportId : "documents"}`} />
      </Switch>
    </>
  )
}


export default ClientFund
