import { useEffect, useState } from 'react'

import { compact, set, unset } from 'lodash'
import { AssetClassFragment } from '../__generated__/graphql'
import { ReportTab } from '../Components/Report/Shared/ReportComponent'
import { GetLookupDataToOptionsProps } from '../Components/ui/LookupOptions'

interface CurrentAssetClassFragmentProps extends AssetClassFragment {
  parent: AssetClassFragment
}

export const useAssetClassHooks = (
  data: GetLookupDataToOptionsProps["data"],
  currentAssetClass: CurrentAssetClassFragmentProps
) => {
  const [parent, setParent] = useState(currentAssetClass.parent)
  const [child, setChild] = useState(() => ({
    code: currentAssetClass.code
  }))
  return { parent, child }
}

export const useChildAssetClassHooks = (
  data: GetLookupDataToOptionsProps["data"],
  currentAssetClass: CurrentAssetClassFragmentProps
) => {
  const { parent } = useAssetClassHooks(data, currentAssetClass)

  let children = data.filter(assetClass => assetClass.code === parent.code)[0]
    .children

  return { children }
}

// Used to remember the current working tab in report components
export const useStoredTab = (componentId: number, tabs: ReportTab[]) => {
  const storeTab = (tab: ReportTab) => {
    const tabIndex = tabs.findIndex((t) => t.value === tab.value)
    localStorage.setItem(`Component-${componentId}-currentTab`, `${tabIndex}`)
  }

  const fetchTab = () => {
    const currentTabIndex = localStorage.getItem(`Component-${componentId}-currentTab`)
    return currentTabIndex ? tabs[parseInt(currentTabIndex, 10)] : null;
  }

  useEffect(()=>{
    return () => {
      localStorage.removeItem(`Component-${componentId}-currentTab`)
    }
  }, [])

  return { fetchTab, storeTab }
}

/**
 * Set and manage the document/page/tab title.
 * Will set the default title to "Callan DNA" and then components can add detail to the title. eg visiting a plan, use setTitle(plan.name) to set the title to "[Plan Name] | Callan DNA"
 * Drilling down to Plan -> Performance -> Return, add pushTitlePart('Return') to the Returns component to set the title to "[Plan Name] - Return | Callan DNA"
 * It is important to use the title methods in a useEffect with a cleanup method returning the opposite method (eg resetTitle, popTitlePart) when the component is unmounted.
 * @returns {setTitlePart, setTitle, resetTitle}
 * setTitle: Removes all parts and sets the top level title eg (Plans | Callan DNA)
 * resetTitle: Reset the title to the base title (Callan DNA)
 * setTitlePart: Set a part of the title at a specific index
 * unsetTitlePart: Remove a part of the title at a specific index
 * pushTitlePart: Add a part to the end of the title
 * popTitlePart: Remove the last part of the title
**/
export const useDocumentTitle = () => {
  const baseTitle = 'Callan DNA';

  const fetchTitleParts = () => {
    const title = document.title;
    const locationString = title.replace(/(\s\|\s)?Callan(\sDNA)?/gi, '')
    const parts = locationString.split(' - ')
    return parts
  }

  const setTitleParts = (parts: string[]) => {
    const compactedParts = compact(parts)

    if (compactedParts.length === 0) {
      document.title = baseTitle;
    } else {
      document.title = `${compactedParts.join(' - ')} | ${baseTitle}`;
    }
  }

  const setTitlePart = (part:string, index:number) => {
    const parts = fetchTitleParts();
    set(parts, index, part)
    setTitleParts(compact(parts))
  }

  const unsetTitlePart = (index:number) => {
    const parts = fetchTitleParts();
    unset(parts, index)
    setTitleParts(compact(parts))
  }

  const pushTitlePart = (part:string) => {
    const parts = fetchTitleParts();
    parts.push(part)
    setTitleParts(parts)
  }

  const popTitlePart = () => {
    const parts = fetchTitleParts();
    parts.pop()
    setTitleParts(parts)
  }

  const resetTitle = () => {
    setTitleParts([]);
  }

  const setTitle = (title: string) => {
    setTitleParts([title]);
  }

  return { setTitlePart, unsetTitlePart, pushTitlePart, popTitlePart, setTitle, resetTitle };
};
