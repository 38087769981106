import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import { compact, first, groupBy, sortBy } from "lodash"
import React, { useState } from "react"
import { History } from "history"
import { useHistory } from "react-router-dom"
import { Col, ListGroup, ListGroupItem } from "reactstrap"
import {
  ConsultantClientListFetchFragment,
  ConsultantClientListListMemberFragment,
  Maybe,
  Plan,
} from "../../../__generated__/graphql"
import {
  ConsultantDashboardAggregatedComponentProps,
  ConsultantDashboardBaseComponent,
  ConsultantDashboardIndividualComponentProps,
} from "./ConsultantDashboardComponent"

const ClientList: React.FC<ConsultantDashboardAggregatedComponentProps> = ({
  component,
  auth,
  draftView,
}) => {
  return (
    <ConsultantDashboardBaseComponent<ConsultantClientListFetchFragment>
      component={component}
      auth={auth}
      draftView={draftView}
      expectedTypename={"ClientList"}
      reactComponent={ClientListDisplay}
    />
  )
}

export type PlanListExpanded = {
  name?: string
  id: number
  subGroup?: PlanListExpanded[]
  plan?: Maybe<Plan>
}

/**
 * based on group and order, save here in case
 */
export const expandClientList = (
  list: ConsultantClientListFetchFragment["list"]
) => {
  let filteredListItems = list?.items?.filter((el) => el.order && el.group)
  let groupedLists = groupBy(filteredListItems, "group")
  const recursivelyCombineGroups = (
    group: ConsultantClientListListMemberFragment[],
    currentHierarchy?: number[]
  ): PlanListExpanded[] => {
    return compact(
      sortBy(group, "order").map((groupMember) => {
        if (groupMember.item?.__typename === "Plan") {
          return {
            name: groupMember.item.name || "",
            id: groupMember.item.id,
            plan: groupMember.item as Plan,
          }
        } else if (
          groupMember.item?.__typename === "ListGroup" &&
          !currentHierarchy?.includes(groupMember.item.id)
        ) {
          const fetchedGroup = groupedLists[groupMember.item.id]
          let combinedGroup = recursivelyCombineGroups(fetchedGroup, [
            ...(currentHierarchy || []),
            groupMember.item.id,
          ])

          const firstMember = first(combinedGroup)
          if (firstMember) {
            return {
              name: first(firstMember.plan?.report)?.client?.name || "",
              id: first(firstMember.plan?.report)?.client?.id || 0,
              plan: firstMember.plan,
              subGroup: combinedGroup,
            }
          }
        }
        return undefined
      })
    )
  }
  // base list is ignored TODO better way?
  let [_, ...result] = recursivelyCombineGroups(groupedLists[1], [])
  return result
}

/**
 * ignore group & order
 */
const expandClientListWithNoGroup = (
  list: ConsultantClientListFetchFragment["list"]
) => {
  let filteredListItems = list?.items?.filter(
    (el) => el.item?.__typename === "Plan"
  ) as ConsultantClientListListMemberFragment[]
  let groupedLists = groupBy(
    filteredListItems,
    (el) => (el.item as Plan).client?.id
  )
  let unorderedList = Object.keys(groupedLists).map((key) => {
    let plans = groupedLists[key]
    let firstMember = first(plans)?.item as Plan
    if (firstMember) {
      return {
        id: parseInt(key), // clientId
        name: firstMember.client?.name || "", // clientName
        subGroup:
          sortBy(plans, "order", "item.name").map((el) => {
            let plan = el.item as Plan
            if (plan)
              return {
                id: plan.id,
                name: plan.name,
                plan,
              } as PlanListExpanded
          }) || [],
      } as PlanListExpanded
    }
  })
  let orderedList =
    sortBy(compact(unorderedList), (el) => el?.name?.toLocaleLowerCase()) || []
  return orderedList
}

const ClientListDisplay: React.FC<
  ConsultantDashboardIndividualComponentProps<ConsultantClientListFetchFragment>
> = (props) => {
  let { settings: clientList } = props
  let stackable = true
  let list = clientList.list
  let showList: PlanListExpanded[] | undefined = undefined
  if (list) {
    showList = expandClientListWithNoGroup(list)
  }
  let history = useHistory<History>()
  const [openItems, setOpenItems] = useState<number[]>([])
  return (
    <Col md={12}>
      <div className={classnames("pane dashboard-pane", { stackable })}>
        {showList?.length === 0 && (
          <>
            <span className="muted-text">
              Your Client List will appear here.
            </span>

            <ListGroup className="recent">
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "85%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "55%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
            </ListGroup>
          </>
        )}
        <div className="client-list">
          {!!showList &&
            showList.map((listItem: PlanListExpanded, idx) => {
              let clientId = listItem?.id
              if (clientId) {
                return (
                  <ClientListRow
                    key={idx}
                    clientId={clientId}
                    planId={listItem.plan?.id || 0}
                    reportId={0}
                    history={history}
                    list={listItem}
                    openItems={openItems}
                    currentHierarchy={[]}
                    setOpenItems={setOpenItems}
                  />
                )
              }
              return <React.Fragment key={idx}></React.Fragment>
            })}
        </div>
      </div>
    </Col>
  )
}

interface ClientListRowProps {
  openItems: number[]
  currentHierarchy: number[]
  setOpenItems: (newOpenItems: number[]) => void
  clientId: number
  planId: number
  reportId: number
  history: History
  list: PlanListExpanded
}

const ClientListRow: React.FC<ClientListRowProps> = ({
  clientId,
  reportId,
  planId,
  list,
  currentHierarchy,
  openItems,
  history,
  setOpenItems,
}) => {
  const hasSubgroup = !!list.subGroup && list.subGroup.length > 0
  const depth = currentHierarchy.length
  const isOpen = openItems.includes(list.id)
  const handleExpand = (e: React.MouseEvent) => {
    if (isOpen) {
      let removedArray = openItems.filter((id) => list.id !== id)
      setOpenItems(removedArray)
    } else {
      setOpenItems([...openItems, list.id])
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    if (currentHierarchy.length === 0) {
      history.push(`/clients/${clientId}/documents`)
    } else {
      if(reportId) {
        history.push(`/clients/${clientId}/${planId}/${reportId}`)
      }else {
        history.push(`/clients/${clientId}/documents`)
      }
    }
  }

  return (
    <>
      <ListGroup
        className={classnames("report-sidebar-item recent flex-row", {
          "has-subgroup": hasSubgroup,
        })}
      >
        <div style={{ marginLeft: depth * 10 }} className="d-flex">
          {(depth !== 0 || hasSubgroup) && (
            <div
              className={`report-sidebar-caret report-sidebar-depth-${depth}`}
              onClick={(e) => handleExpand(e)}
            >
              {hasSubgroup && (
                <FontAwesomeIcon
                  icon={isOpen ? "caret-down" : "caret-right"}
                  size="sm"
                />
              )}
            </div>
          )}
        </div>
        <ListGroupItem tag="a" className="report-sidebar-title">
          <h3 onClick={handleClick}>{list.name}</h3>
        </ListGroupItem>
      </ListGroup>
      {isOpen && hasSubgroup && (
        <>
          {list.subGroup?.map((listItem: PlanListExpanded, idx) => {
            let plan = listItem.plan as Plan
            let report = first(plan.report)
            let reportId = report?.id || 0
            return (
              <ClientListRow
                key={`${idx}-${currentHierarchy.length + 1}`}
                clientId={clientId}
                planId={plan.id}
                reportId={reportId}
                list={listItem}
                history={history}
                openItems={openItems}
                currentHierarchy={[...currentHierarchy, list.id]}
                setOpenItems={setOpenItems}
              />
            )
          })}
        </>
      )}
    </>
  )
}

export default ClientList
