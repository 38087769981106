import {
  CellClassParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
} from "@ag-grid-community/core"
import iassign from "immutable-assign"
import { camelCase, find, first, get, set, upperFirst } from "lodash"
import moment from "moment"
import numbro from "numbro"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _ from "lodash"
import { useState } from 'react'
import { renderToString } from "react-dom/server"
import { LoadStatusCode, LoadTypeCode, ManagerTypeLookup, Maybe, ReportScheduleReportFragment, StatusCode } from "../__generated__/graphql"
import { appDate } from "../Context/CalendarContext"
import { textLink } from "./agGridHelpers"
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from "./constant"

export const currencySymbolMapping = {
  "AULDOL": "$",
  "CANDOL": "$",
  "USADOL": "$",
  "JPNYEN": "¥",
  "EURECU": "€",
  "UKPOU": "£",
}

export const currencyFormatter = (params: ValueFormatterParams, inFormat?: numbro.Format) => {
  // ag-grid helper method
  let value = params.value
  let ending
  let format = inFormat || "$0,0.00"
  if (typeof value == "string" && value.endsWith(" EST")) {
    ending = " EST"
    value = parseFloat(value.slice(0, -4))
  }
  if (Number.isFinite(value)) {
    return numbro(value || 0).format(format) + (ending || "")
  } else if (typeof value == "string" && numbro(value).value() !== undefined && !Number.isNaN(numbro(value).value())){
    return numbro(value).format(format) + (ending || "")
  } else {
    return "-"
  }
}

export const percentFormatter = (params: ValueFormatterParams, fractionalDigits = 2) => {
  // ag-grid helper method
  const numbroString = `0,0.${"0".repeat(fractionalDigits)}`
  let value = params.value
  if (Number.isFinite(value) && value !== 0) {
    return numbro(value || 0).format(numbroString) + "%"
  } else if (typeof value == "string" && numbro(value).value() !== undefined && !Number.isNaN(numbro(value).value())){
    return numbro(value).format(numbroString) + "%"
  } else {
    return "-"
  }
}

export const numberParser = (params: ValueParserParams) => {
  // ag-grid helper method
  let newValue = params.newValue
  if (newValue === undefined || newValue === null || newValue === "") {
    return null
  } else {
    return numbro(newValue).value()
  }
}

export const falsyFormatter = (params: ValueFormatterParams) => {
  // format  undefined , null , NaN , 0 , "" (empty string), and false into "-"
  if (!params.value) {
    return "-"
  } else {
    return params.value
  }
}

export const simpleMappingFormatter = (
  params: ValueFormatterParams,
  mapping: { [key: string]: string }
) => {
  // format  undefined , null , NaN , 0 , "" (empty string), and false into "-"
  if (!params.value) {
    if (params.value === false) {
      return mapping[params.value]
    }
    return "-"
  } else {
    return mapping[params.value]
  }
}

export const currentVersionUpdatedGetter = (
  params: ValueGetterParams
) => {
  const document = params.data
  const currentVersion = find(document.versions, ["id", document.versionId])
  return moment(get(currentVersion, params.colDef.field || "")).format(DATE_API_FORMAT)
}

export const dateFormatter = (
  // show formatted date or default falsyformatted date
  params: ValueFormatterParams
) => {
  const date: string = params.value || ""
  return date? moment(date).format(DATE_DISPLAY_FORMAT) : falsyFormatter(params)
}

export const dateFilterParams  = {
  // userInputString is in YYYY-MM-DD
  comparator: (localDateObject: Date, userInputString: Maybe<string>, blankPosition?: "bottom"| "top") => {
    var dateAsString = userInputString
    let localDate = moment(localDateObject, "YYYY-MM-DD")
    let userInputDate = moment(dateAsString, "YYYY-MM-DD")
    // default falsy date at bottom
    let position = blankPosition || "bottom"
    if(position === "top") {
      if (!dateAsString && !localDateObject) {
        return 0
      }else if (!dateAsString) {
        return 1
      }else if (!localDateObject) {
        return -1
      }
    }else {
      // bottom
      if (!dateAsString && !localDateObject) {
        return 0
      }else if (!dateAsString) {
        return -1
      }else if (!localDateObject) {
        return 1
      }
    }

    // See example https://www.ag-grid.com/react-data-grid/filter-date/#example-date-filter
    if (userInputDate.isBefore(localDate, 'day')) {
      return -1
    }
    if (userInputDate.isAfter(localDate, 'day')) {
      return 1
    }
    return 0
  },
  browserDatePicker: true,
  // need to upgrade ag-grid version for the params below.
  minValidYear: 1900,
  maxValidYear: appDate.year(),
  debounceMS: 500,
}

export const falsyDateAtBottomComparator = (localDateObject: Date, userInputString: Maybe<string>) => dateFilterParams.comparator(localDateObject, userInputString, "bottom")

export const falsyDateAtTopComparator = (localDateObject: Date, userInputString: Maybe<string>) => dateFilterParams.comparator(localDateObject, userInputString, "top")

/** for managerdocs subpage.*/
export const DocumentSubTypeComparator = (valueA: string, valueB: string) => {
  if (valueA === valueB) return 0
  // sort order "Performance/Commentary", "Market Overview", then alphabet order.
  if(valueA === "Performance/Commentary") {
    return -1
  }else if (valueB === "Performance/Commentary") {
    return 1
  }else if(valueA === "Market Overview") {
    return -1
  }else if (valueB === "Market Overview") {
    return 1
  }
  return (valueA > valueB) ? 1 : -1;
}

/**
 * For column Value like product.isInactive with header name Status, so export column is Active if raw value is false.
*/
export const mappingValueGetter = (params: ValueGetterParams, field: string, mapping: { [key: string]: string }) => {
  let data = params?.data
  if (data) {
    let value = _.get(data, field)
    return mapping[value]
  } else {
    return "-"
  }
}

export const totalAUMValueGetter = (params: ValueGetterParams) => {
  let aum = params?.data?.totalAssetsUnderManagement
  if (aum && aum.length > 0) {
    return aum[0].assetsUnderManagement || 0
  } else {
    return 0
  }
}

export const getLatestPlanAsset = (params: ValueGetterParams | CellClassParams) => {
  // get assets value of latest date
  let assets = params?.data?.assets
  let latestAsset = {date: null, value: null}
  if (assets && assets.length > 0) {
    let orderedAssets = _.orderBy(assets, "date", "desc")
    if (orderedAssets?.length > 0) {
      latestAsset = orderedAssets[0]
    }
  }
  return latestAsset
}

export const planAssetsValueGetter = (params: ValueGetterParams) => {
  return getLatestPlanAsset(params)?.value || null
}

export const managerProductCountGetter = (params: ValueGetterParams) => {
  let products = params.data.products
  return products?.length || 0
}

export const recentAUMValueGetter = (params: ValueGetterParams) => {
  const row = params.data
  if (!row) {
    return
  }
  let latestAum
  if (row.product && row.product.latestAum) {
    latestAum = row.product.latestAum
  } else if (row.aum) {
    latestAum = row.aum
  } else {
    return 0
  }
  return latestAum.aum
}

export const productLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/products/" + params.data?.product?.id})
}

export const documentClientLinkRenderer = (params: ICellRendererParams, isCallan?:  boolean) => {
  if(params.data.__typename === "File" && isCallan) {
    return textLink({text: params.value, url: "/clients/" + params.data?.clientId + (params.data?.planId ? `/${params.data?.planId}` : "") + "/documents/" + params.data?.id})
  } else if (params.data.__typename === "Report") {
    const planId = params.data?.planId || params.data?.plans?.[0]?.id
    if(params.data.category.code === "FPROF") {
      return textLink({text: params.value, url: "/clients/" + params.data?.clientId + (planId ? `/${planId}` : "") + "/" + params.data?.id})
    } else {
      return textLink({text: params.value, url: "/clients/" + params.data?.clientId + (planId ? `/${planId}` : "") + "/reports/" + params.data?.id})
    }
  }
  return params.value
}

export const portfolioVehicleLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/products/" + params.data?.relatedVehicle?.vehicle?.product?.product?.id + "/vehicles/" + params.data?.relatedVehicle?.vehicle?.id, showHref: true, newTab: true})
}

export const portfolioProductLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/products/" + params.data?.relatedVehicle?.vehicle?.product?.product?.id + "/profile", newTab: true})
}

export const portfolioManagerLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/managers/" + params.data?.relatedVehicle?.vehicle?.product?.product?.manager?.id + "/profile", newTab: true})
}

export const reportClientLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/clients/" + params.data?.client?.id + "/documents/"})
}

export const fundManagerDocumentLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/clients/" + params.data?.clientId + (params.data?.planId ? `/${params.data?.planId}` : "") + "/managerdocs/" + params.data?.id, showHref: true})
}

export const stoplightVehicleLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/products/" + params.data?.product?.product?.id + "/vehicles/" + params.data?.vehicle?.id, showHref: true})
}

export const stoplightProductLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/products/" + params.data?.product?.product?.id + "/profile"})
}

export const stoplightManagerLinkRenderer = (params: ICellRendererParams) => {
  if(params.data) {
    // Manager
    return textLink({text: params.data?.manager?.name, url: "/managers/" + params.data?.manager?.id + "/profile"})
  } else {
    // List header
    return params.value
  }
}

export const ListLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/lists/" + params.data?.id})
}

export const statusValueGetter = (params: ValueGetterParams) => {
  if(params.data){
    const status = get(params.data, params.colDef.field || "")
    return status ? statusEnumToValue(status.status) : ""
  } else {
    return "All Groups"
  }
}

export const statusValueGetterWithDate = (params: ValueGetterParams) => {
  if(params.data){
    const status = get(params.data, params.colDef.field || "")
    return status ? status.lastChangedDate : ""
  } else {
    return "All Groups"
  }
}


export const managersLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/managers/" + params.data?.id})
}

export const clientsLinkRenderer = (params: ICellRendererParams) => {
  // for plan list table
  return textLink({text: params.value, url: "/clients/" + params.data?.client?.id, showHref: true})
}

export const plansLinkRenderer = (params: ICellRendererParams) => {
  // for plan list table
  return textLink({text: params.value, url: "/clients/" + `${params.data?.client?.id}/${params.data.id}`, showHref: true})
}

export const falsyAtBottomComparator = (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: any) => {
  // falsy value at bottom
  if(!valueA) {
    return 1
  }else if(!valueB) {
    return -1
  }
  // eslint-disable-next-line eqeqeq
  return (valueA == valueB) ? 0 : (valueA > valueB) ? 1 : -1;
}

export const listOrderFalsyAtBottomComparator = (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
  // falsy value at bottom
  if(valueA === valueB) return 0
  if (valueA === "-" || !valueA) {
    return 1
  } else if (valueB === "-" || !valueB) {
    return -1
  }
  let [firstA, secondA] = (valueA).split('.').map((el: string) => parseInt(el))
  let [firstB, secondB] = (valueB).split('.').map((el: string) => parseInt(el))
  if(firstA < firstB) {
    return -1
  }else if(firstA > firstB) {
    return 1
  }else {
    return secondA ===  secondB ? 0 : secondA > secondB ? 1 : -1
  }
}

export const portfolioLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/clientportfolios/" + params.data?.id, showHref: true})
}

export const planLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/plans/" + params.data?.id, showHref: true})
}

export const reportPlanLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/plans/" + first(params.data?.plans as ReportScheduleReportFragment["plans"])?.id, showHref: true})
}

export const ReportLinkRenderer = (params: ICellRendererParams) => {
  return textLink({text: params.value, url: "/reports/" + params.data?.id})
}

// report name, link Fund Profile
export const ReportFundProfileRenderer = (params: ICellRendererParams, newTab?: boolean) => {
  // for report
  return textLink({text: params.data.name, url: "/clients/" + params.data.client?.id + "/" + params.data.plans?.[0]?.id + "/" + params.data?.id, newTab: newTab})
}

/* currently not in use, I've used route regex match to remove slashes in the middle
   and end of url(in mainRoutes.tsx) */

export const removeUrlTrailingSlash = (url: string) => {
  const noTrailingSlashUrl: string = url.endsWith("/")
    ? removeUrlTrailingSlash(url.substring(0, url.length - 1))
    : url
  return noTrailingSlashUrl
}
/**
 * Converts string to uniform date format, default to DATE_API_FORMAT
 * @param value The string to convert, YYYY-MM-DD or YYYY-M-D format.
 * @param dateFormat, optional param, target date format, default DATE_API_FORMAT(YYYY/MM/DD)
 * @return valid date? the formatted string, else false.
 **/
export const parseDate = (
  value: string,
  dateFormat: string = DATE_API_FORMAT
) => {
  //test if date is YYYY-MM-DD and YYYY-M-D
  const regEx = /^\d{4}\-\d{1,2}\-\d{1,2}$/
  if (!value.match(regEx)) return false // Invalid format
  return moment(value).format(dateFormat)
}
/**
 * Converts string to PascalCase.
 *
 * @param value The string to convert.
 * @return Returns the PascalCased string.
 */
export const parsePascalCase = (value: string) => {
  if (!value) {
    return value
  }
  return upperFirst(camelCase(value))
}
/**
 * Returns a list of quarters between the two dates
 * @param startDate The start date in the DATE_API_FORMAT format
 * @param endDate The end date in the DATE_API_FORMAT format
 * @return An array of strings containing the requested dates
 **/
export const listQuarters = (startDate: string, endDate: string) => {
  var list: string[] = []
  var firstDate = moment(startDate)
  const finalDate = moment(endDate)
  list.unshift(firstDate.format(DATE_API_FORMAT))
  while (finalDate > firstDate) {
    firstDate = firstDate.add(3, "months")
    list.unshift(firstDate.endOf("quarter").format(DATE_API_FORMAT))
  }
  return list
}
/**
 * Converts quarterEndDate to YYYYMM format.
 *
 * @param value The string to convert.
 * @return Returns the formatted string.
 */
export const parseDateAsQrtDate = (date: Date) => {
  return moment(date, "YYYY-MM-DD")
    .endOf("quarter")
    .format("YYYYMM")
}

/**
 * Converts YYYYMM date string to quarterEndDate YYYY-MM-DD format.
 * @param value The string to convert.
 * @return Returns the formatted string.
 */
export const parseQrtDateAsDate = (date: string) => {
  return moment(date, "YYYYMM")
    .endOf("quarter")
    .format("YYYY-MM-DD")
}

/**
 * Converts YYYY-MM-DD HH:mm:ss DateTime string into MMM DD,YYYY HH:mm:ss format.
 * @param value The string to convert.
 * @return Returns the formatted string.
 */
export const parseDateTime = (dateTime: string) => {
  let target = moment(dateTime, "YYYY-MM-DD HH:mm:ss")
  // check for invalid dates
  if (target.isValid()) {
    return target.format("lll")
  }
  return ""
}

export interface ManagerType {
  PE: boolean
  LGSH: boolean
  RA: boolean
  PEFOF: boolean
  HFFOF: boolean
  HF: boolean
}
/**
 * Returns the types of the manager
 *
 * @param managerTypes the manager types array
 * @return Returns an object with boolean values for each of the types.
 */
export const managerTypeBreakdown = (
  managerTypes: ManagerTypeLookup[]
): ManagerType => {
  let baseTypes = {
    PE: false,
    LGSH: false,
    RA: false,
    PEFOF: false,
    HFFOF: false,
    HF: false,
  }

  managerTypes.forEach((element) => {
    if (element.code) {
      baseTypes[element.code] = true
    }
  })

  return baseTypes
}

/**
 * helper function for update multiple select
 * @param value the new Clicked value
 * @param state the state array
 * @return if value exists in the state, return the state, value removed,
 * if value doesn't exist, return state with value inserted.
 *
 */

export const addOrDeleteSelectToState = (value: any, state: any[]) => {
  let index = state.indexOf(value)
  let newState: any[] = []
  if (index < 0) {
    newState = [...state, value]
  } else {
    newState = [...state.slice(0, index), ...state.slice(index + 1)]
  }
  console.log(state, index, newState)
  return newState
}

type GetNewStateProps = {
  state: { [property: string]: any }
  newValue: any // could be array
  property: string
  type?: string
}

export const getNewStateObject = ({
  state,
  newValue,
  property,
  type,
}: GetNewStateProps) => {
  let clonedState = state || {}
  let propertyArray = property.split(".")
  let singleProperty = propertyArray.length === 1
  let lastProperty = singleProperty
    ? property
    : propertyArray[propertyArray.length - 1]
  let frontProperties = singleProperty
    ? ""
    : property.slice(0, -lastProperty.length - 1)
  let newState: any
  if (!frontProperties) {
    newState = { ...clonedState, [property]: newValue }
  } else {
    let frontObject = get(clonedState, frontProperties)
    if (!frontObject) {
      set(clonedState, frontProperties, {})
    }
    let path = frontProperties.split(".")
    newState = iassign(
      clonedState,
      path,
      (state) => (state? {
        ...state,
        [lastProperty]: newValue,
      }: {[lastProperty]: newValue})
    )
  }
  return newState as typeof clonedState
}

/**
 * This function takes a string like "person" and capitalizes the first letter, returning a string like "Person"
 * @param string - word to be capitalized
 */

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const statusEnumToValue = (statusCode: StatusCode) => {
  switch (statusCode) {
    case StatusCode._1:
      return "Within Expectations"
    case StatusCode._2:
      return "Notable"
    case StatusCode._3:
      return "Cautionary"
    case StatusCode._4:
      return "Under Review"
    default:
      return ""
  }
}

export const statusColor = (status?: string | null) => {
  switch (status) {
    case "Within Expectations":
      return "writeup-status1"
    case "Notable":
      return "writeup-status2"
    case "Cautionary":
      return "writeup-status3"
    case "Under Review":
      // CAL-1631
      return "writeup-status4"
    default:
      return ""
  }
}

/**
 * @desc    Format numbro values
 * @param   {any} value
 * @param   {string} format e.g. '$0,0' default is '0,0'
 * @return  {string}
 * */
export const numbroFormatter = (value: any, format: numbro.Format | string = '0,0', defaultValue = "-") => {
  if (value) return numbro(value).format(format)
  if(_.isUndefined(value)) return defaultValue
}

export const booleanFormatter = (value: boolean | null | undefined, defaultValue = "-") => {
  if(value === true){
    return "True"
  }else if(value === false){
    return "False"
  }
  return defaultValue || ""
}

export const headerComponentWithIcon = (tooltipId: string) => {
  return(
    `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eText" class="ag-header-cell-text"></span>
        <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span id=${tooltipId} class="ag-header-icon ag-header-label-icon ag-tooltip-icon">
        ${renderToString(
          FontAwesomeIcon({ icon: "question-circle", className: "mx-1" })
        )}</span>
        </div>
</div>`
  )
}

export const isGlidePathVersionActive = (invalidDate: string|null) => {
  return !(!!invalidDate && moment().isAfter(invalidDate, "days"))
}

/**
 * Force component to update.
 * To use, const forceUpdateNow = useForceUpdate()
 * call forceUpdateNow() when necessary.
*/
export const useForceUpdate = () =>{
  // integer state
  const [value, setValue] = useState(0);
  // update the state to force render
  return () => setValue(value => value + 1);
}

export const getUrlFor = (object: any) => {
  if (object.__typename === "Bank") return `/custodians/${object?.id}/overview`
  if (object.__typename === "Manager") return `/managers/${object?.id}/profile`
  if (object.__typename === "RecordKeeper") return `/recordkeepers/${object?.id}/overview`
  if (object.__typename === "Client") return `/clients/${object?.id}`
  if (object.__typename === "Person") return `/managers/${object?.employer?.id}/people/${object?.id}`
  if (object.__typename === "GlidePath") return `/glidepaths/${object?.id}/overview`
  if (object.__typename === "File") return `/documents/${object?.id}`
  if ("product" in object) return `/products/${object?.product?.id}/profile`
  if ("vehicle" in object) return `/products/${object?.vehicle?.product?.id}/vehicles/${object?.vehicle?.id}`

  return undefined
}

export const loadTypeEnumToValue = (loadTypeCode: LoadTypeCode) => {
  switch (loadTypeCode) {
    case LoadTypeCode._1:
      return "Assets"
    case LoadTypeCode._2:
      return "Cash Flows"
    case LoadTypeCode._3:
      return "Return/Assets"
    case LoadTypeCode._4:
      return "Characteristics"
    default:
      return ""
  }
}

export const LoadTypeCodeValues = Object.values(LoadTypeCode)
export const LoadTypeCodeMapping = LoadTypeCodeValues.reduce((acc, code) => {
  return { ...acc, [code]: loadTypeEnumToValue(code as LoadTypeCode) }
}, {} as { [key: string]: string })

enum LoadStatusPendingCode {
  /** interim status: Importing */
  _0 = '_0',
}

type LoadStatusState = LoadStatusCode | LoadStatusPendingCode

export const loadStatusEnumToValue = (loadTypeCode: LoadStatusState) => {
  switch (loadTypeCode) {
    case LoadStatusPendingCode._0:
      return "Pending"
    case LoadStatusCode._1:
      return "Imported"
    case LoadStatusCode._2:
      return "Import Failed"
    case LoadStatusCode._3:
      return "Published"
    case LoadStatusCode._4:
      return "Publish Failed"
    default:
      return ""
  }
}

export const LoadStatusCodeValues = [...Object.values(LoadStatusCode), ...Object.values(LoadStatusPendingCode)]
export const LoadStatusCodeMapping = LoadStatusCodeValues.reduce((acc, code) => {
  return { ...acc, [code as any]: loadStatusEnumToValue(code as any) }
}, {} as { [key: string]: string })
