import { History } from 'history'
import { find, findIndex, first } from 'lodash'
import React from 'react'
import { match, Redirect, Route, Switch, useParams } from 'react-router-dom'
import { Button, Col, Container, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { isCallanUser } from '../../helpers/person'
import { listReportExpanded, expandReportList } from '../../helpers/report'
import { MeFragment, ReportsFragment, useClientReportsQuery, useMeQuery } from '../../__generated__/graphql'
import PlaceHolder from '../ui/PlaceHolder'
import { ReportDisplayType } from '../Report/Shared/ReportComponent'
import ReportMain from '../Report/Shared/ReportMain'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { documentTableData } from './ClientDocumentList'

type idProps = {
  clientId: number
  planId?: number
  history: History
  auth: Auth
  match: match<{ reportId: string }>
  addHeading?: React.ReactNode
}

const ClientReportDetail: React.FC<idProps> = ({ clientId, planId, history, auth, match, addHeading }) => {

  const { loading, data, error } = useClientReportsQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: clientId, liveView: true, draftView: false }
  })

  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined
  const isCallan = userData ? isCallanUser(userData) : false
  const reportId = match.params.reportId ? parseInt(match.params.reportId) : 0

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!!data  && data.org?.__typename === 'Client' && data.org.report) {
    let report = find(data.org.report, {"id": reportId}) as ReportsFragment
    if(!report && planId) {
      let plan = find(data.org.plans, {"id": planId})
      if(plan) {
        report = find(plan.report, {"id": reportId}) as ReportsFragment
      }
    }
    const matchedReport = report?.id === reportId
    let showList:listReportExpanded[] | undefined = undefined
    if (isCallan && report?.draftList){
      showList = expandReportList(report.draftList)
    } else if (report?.liveList) {
      showList = expandReportList(report.liveList)
    }

    const {reportId: reportIdString } = match.params
    const urlWithoutType = reportIdString
      ? match.url.slice(0, -reportIdString.length - 1)
      : match.url

    if(!(isCallan && report?.draftList) && !showList) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className='pane pane-table'>
                <UncontrolledAlert color="secondary" className='mt-2'>
                  <h4>No published report available</h4>
                </UncontrolledAlert>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }

    if(!showList) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className='pane pane-table'>
                <UncontrolledAlert color="danger">
                  <h4>Can't find List to view</h4>
                </UncontrolledAlert>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container fluid>
        <Switch>
          <Route
            exact
            path={`${match.url}/:portfolioId`}
            render={(params) =>
              <ReportHolder
                list={showList || []}
                match={params.match}
                clientId={clientId}
                reportId={reportId}
                history={history}
                auth={auth}
                user={user}
                report={report}
                addHeading={addHeading}
              />}
            >
          </Route>
          {matchedReport && (
            <Redirect
              to={`${match.url}/${first(showList)?.id}`}
            />
          )}
          {!matchedReport && (
            <PlaceHolder />
          )}
        </Switch>

      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

interface ReportHolderProps {
  clientId: number
  reportId: number
  history: History
  auth: Auth
  match: {params: {portfolioId?: string}}
  user?: MeFragment
  list: listReportExpanded[]
  report: ReportsFragment
  addHeading?: React.ReactNode
}

function ReportHolder({
  list,
  history,
  user,
  auth,
  clientId,
  reportId,
  match,
  report,
  addHeading,
  }: ReportHolderProps) {

  const portfolioId = match.params.portfolioId
  return (
    <Row>
      <ReportMain
        portfolioId={portfolioId ? parseInt(portfolioId) : undefined}
        clientId={clientId}
        reportId={reportId}
        history={history}
        auth={auth}
        list={list}
        user={user}
        view={ReportDisplayType.Live}
        report={report}
        addHeading={addHeading}
      />
    </Row>
  )
}

type ReportAsDocumentProps = {
  clientId: number
  planId?: number
  history: History
  auth: Auth
  filteredDocuments: documentTableData[]
  match: match<{ reportId: string }>
}

export const ClientReportAsDocument: React.FC<ReportAsDocumentProps> = (props) => {
  const { clientId, planId, filteredDocuments, history, auth, match } = props
  const reportId = match.params.reportId ? match.params.reportId : ""

  const documentIndex = findIndex(filteredDocuments, (doc) =>  doc.id === parseInt(reportId) && doc.__typename === "Report")
  const urlType = "reports"
  const heading = (
    <>
        <div className="mr-2 pr-3 border-right">
          <Button color="blue-link" className="ml-auto mt-0 mb-0" onClick={() => history?.push("/clients/" + clientId + (planId ? `/${planId}` : "") + `/${urlType}`)}>
            <FontAwesomeIcon
              icon={"chevron-left"}
              className="mr-2 "
              size="sm"
            />
            Documents + Reports
          </Button>
        </div>
        <div className="mr-2 pr-3 border-right d-flex flex-nowrap align-items-center">
          <Button color="blue-link" className="mr-2" disabled={documentIndex < 1} onClick={() => {
            let usedUrlType = urlType
            if(filteredDocuments[documentIndex-1].__typename === "File"){
              usedUrlType = "documents"
            }
            history?.push("/clients/" + clientId + (planId ? `/${planId}` : "") + `/${usedUrlType}/` + filteredDocuments[documentIndex-1].id)}}>
            <FontAwesomeIcon
              icon={"chevron-left"}
              className=""
              size="sm"
            />
          </Button>
          {documentIndex+1}
          {" "} Of {" "}
          {filteredDocuments.length}
          <Button color="blue-link" className="ml-2" disabled={documentIndex >= filteredDocuments.length -1} onClick={() => {
            let usedUrlType = urlType
            if(filteredDocuments[documentIndex+1].__typename === "File"){
              usedUrlType = "documents"
            }
            history?.push("/clients/" + clientId + (planId ? `/${planId}` : "") + `/${usedUrlType}/` + filteredDocuments[documentIndex+1].id)}}>
            <FontAwesomeIcon
              icon={"chevron-right"}
              className=""
              size="sm"
            />
          </Button>
        </div>
    </>
  )

  return (
    <div className='mx-negative-container position-relative'>
      <div className='position-sticky z-index-1203 top-0'>
        {auth.checkPermissions(["view:all_clients"]) &&
          <Button color="blue-link" className="nav-right-link" onClick={() => history.push(`/reports/${reportId}`)}>
            <span className="pr-1">Report Editor</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        }
      </div>
      <ClientReportDetail {...props} addHeading={heading}></ClientReportDetail>
    </div>
  )
}


export default ClientReportDetail
